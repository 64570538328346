import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, retry, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  categoryData: any[] = [];

  constructor(private http: HttpClient) {}

  getCategories() {
    return this.http.get(environment.baseUrl + '/v1/category').pipe(retry(3));
  }

  setCategoryData(res: any) {
    this.categoryData = res;
  }

  fetchCateegoryDataByName(name: any) {
    if (this.categoryData) {
      return this.categoryData.find((item: any) => item.Name == name);
    }
  }

  fetchCategoryData() {
    return this.categoryData;
  }

  exportData(request: any) {
    return this.http.get(
      environment.baseUrl +
        `/v1/export/${request.categoryid}/${request.type}?filters=${request.filterData}`,
      { observe: 'response', responseType: 'blob' }
    );
  }
}
