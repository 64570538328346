import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { retry, map, of } from 'rxjs';
import productionUnitCountries from '../../mockJson/productionunitCountries.json';
import productionUnitCountryCode from '../../mockJson/productionunitsCountryCode.json';

@Injectable({
  providedIn: 'root',
})
export class ProductionUnitsService {
  constructor(private http: HttpClient) {}

  getProductionUnitsCountry() {
    return this.http.get(
      environment.baseUrl +
        environment.productionUnitService.productionCountries
    );
    // return of(productionUnitCountries)
  }

  getProductionUnitsDetailsByCountry(countryCode: string) {
    const params = new HttpParams().set('country-code', countryCode);
    return this.http
      .get(
        environment.baseUrl + environment.productionUnitService.productionUnits,
        {
          params: params,
        }
      )
      .pipe(retry(3));
    // return of(productionUnitCountryCode);
  }
}
