import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubjectService {
  refreshSubject = new BehaviorSubject(false);
  loaderSubject = new BehaviorSubject(false);
  refreshSubject$ = this.refreshSubject.asObservable();
  leftMenuNavSubject = new BehaviorSubject(false);
  tokenReceivedSubject = new BehaviorSubject(false);

  constructor(private http: HttpClient) {}

  toggleLoaderSubject(data: boolean) {
    this.loaderSubject.next(data);
  }

  getLoaderSubject() {
    return this.loaderSubject.asObservable();
  }

  toggleLeftMenuNavSubject(data: boolean) {
    this.leftMenuNavSubject.next(data);
  }

  onReceiveTokenSubject(data: boolean) {
    this.tokenReceivedSubject.next(data);
  }
}
