[
  {
    "title": "Home",
    "url": "",
    "description": "Dashboard",
    "isActive": true,
    "image": ""
  },

  {
    "title": "Dealers/Workshops",
    "url": "/dealersWorkshops",
    "description": "Dealers/Workshops",
    "isActive": false,
    "image": "dealers"
  },
  {
    "title": "Distributors",
    "url": "/distributors",
    "description": "Distributors",
    "isActive": false,
    "image": "distributors"
  },
  {
    "title": "Assistance",
    "url": "/assistance",
    "description": "Assistance",
    "isActive": false,
    "image": "assistance"
  },

  {
    "title": "Customer Workshops",
    "url": "/customerWorkshops",
    "description": "Customer Workshops",
    "isActive": false,
    "image": "customerWorkshops"
  },

  {
    "title": "Finance Companies",
    "url": "/financeCompanies",
    "description": "Finance Companies",
    "isActive": false,
    "image": "financeCompanies"
  },
  {
    "title": "Production Units",
    "url": "/productionUnits",
    "description": "Production Units",
    "isActive": false,
    "image": "productionUnits"
  }
]
