<tds-header
  class="mainmenu"
  style="border-bottom: 1px solid var(--tds-header--basic-element-border)"
>
  <tds-header-title
    style="
      border-right: 1px solid var(--tds-header--basic-element-border);
      width: 164px;
    "
  >
    <!--Scania image is added from css in below a tag -->
    <a href=""></a>
  </tds-header-title>
  <!-- <div style="height: 30px; position: relative; left: -16px">
    <tds-divider orientation="vertical"></tds-divider>
  </div> -->
  <tds-header-item
    style="
      border: none !important;
      color: #041e42 !important;
    "
  >
  <tds-header-title>{{ title | uppercase }}</tds-header-title>
  </tds-header-item>
 
  <tds-header-dropdown slot="end"  no-dropdown-icon class="user-dropdown">
    <div slot="icon" [hidden]="!authService.isAuthenticated">
      <tds-icon name="profile" size="25px"></tds-icon>
    </div>
    <tds-header-dropdown-list size="lg" *ngIf="authService.isAuthenticated">
      <tds-header-dropdown-list-user
        header="{{ identityClaims?.given_name }} {{ identityClaims?.family_name }}"
        subheader="{{ identityClaims?.email }}"
      ></tds-header-dropdown-list-user>
      <tds-header-dropdown-list-item>
        <a routerLink="#" (click)="logout()">
          <div class="tds-u-pl1">Logout</div>
        </a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
  </tds-header-dropdown>

  <tds-header-brand-symbol slot="end">
    <a
      href=""
      aria-label="Scania - red gryphon on blue shield"
      class="bg-white"
    ></a>
  </tds-header-brand-symbol>
</tds-header>
