@if((openingHours && openingHours.length) || openingHourComments){
<div>
  <!-- <h5 class="tds-headline-05 mb-2 mt-1">Business Hours</h5> -->

  @for (item of openingHours; track $index ;) { @if(item && item.length){

  <div>
    <label class="inline-block">
      @for (obj of item; track obj.Day;let idx = $index) {
      {{ obj.Day + (idx < item.length - 1 ? "," : "") | titlecase }}
      }
    </label>
    <p class="py-0 my-0 mb-2">{{ item[0].Time }}</p>
  </div>
  } } @if (openingHourComments) {
  <label class="inline-block mb-1 mt-3">
    <strong class="tds-detail-02">Opening Hours Comments: </strong> <br>
    {{ openingHourComments }}
  </label>
  }
</div>
} @else{
<div>
  <a class="tds-text-grey-700"> No information available</a>
</div>
}
