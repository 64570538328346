export const environment = {
  baseUrl: 'https://api.sis.devtest.gf.aws.scania.com/api',
  production: false,
  authConfig: {
    region: 'eu-north-1',
    userPoolId: 'eu-north-1_FZJlGRZgx',
    userPoolWebClientId: 'd667ivospf6ggp9k48ep6abe7',
    azureAdLogoutUri:
      'https://login.microsoftonline.com/3bc062e4-ac9d-4c17-b4dd-3aad637ff1ac/oauth2/v2.0/logout',
    oauth: {
      domain: 'https://sis-internal-dev.auth.eu-north-1.amazoncognito.com',
      issuer:
        'https://cognito-idp.eu-north-1.amazonaws.com/eu-north-1_FZJlGRZgx',
      scope: 'email openid profile',
      redirectSignIn:
        'https://internal.sis.devtest.gf.aws.scania.com/auth-callback',
      redirectSignOut: 'https://internal.sis.devtest.gf.aws.scania.com/logout',
      responseType: 'code',
    },
  },
  assistanceService: {
    assistanceCountries: '/v1/assistances/AssistanceCountries',
    assistances: '/v1/assistances/assistances',
  },
  dealersService: {
    dealerCountries: '/v2/dealers/dealercountries ',
    dealers: '/v2/dealers/dealers',
  },
  distributorService: {
    distributorCountries: '/v1/distributors/distributorcountries',
    distributors: '/v1/distributors/distributors',
  },
  productionUnitService: {
    productionCountries: '/v1/productionunits/productionunitcountries',
    productionUnits: '/v1/productionunits/productionunits',
  },
  financeService: {
    financeCountries: '/v1/financecompanies/financecompanycountries',
    financeCompanies: '/v1/financecompanies/financecompanies',
  },
  customerWorkshopService: {
    customerWorkshopCountries:
      '/v1/customerworkshopservices/customerworkshopcountries',
    customerWorkshops: '/v1/customerworkshopservices/customerworkshops',
  },
  userService: {
    photo: '/v1/user/photo',
  },
  isFax : false
};
