import { ErrorHandler } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class CustomErrorHandlerService implements ErrorHandler {
  toastMessage: any = new BehaviorSubject({});
  toastMessage$ = this.toastMessage.asObservable();

  handleError(error: any): void {
    console.log('CustomErrorHandlerService' + error);
    this.toastMessage.next({
      severity: 'error',
      summary: 'Error',
      detail: error.message,
      key: 'tl',
    });
  }

  getToastMessage() {
    return this.toastMessage.asObservable();
  }
}
