<div class="mainContainer">
  <ng-container>
    <div class="pt-4 pb-4 pl-10 pr-10 sm:pl-5 sm:pr-5 md:pl-7 md:pr-7">
      <span class="tds-text-grey-958 font-bold text-base">Filter By:</span>

      <div
        class="grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 inline-grid w-11/12 ml-5 filterGrid"
      >
        <tds-dropdown
          #countryRef
          ngDefaultControl
          name="country"
          placeholder="Select a Country"
          size="sm"
          open-direction="down"
          class="inline-block"
          (tdsChange)="changeCountry('Country', $event.detail.value)"
          default-value=""
          class="dropdownWid"
          filter="true"
        >
          @for (country of countryLists; track $index) {
          <tds-dropdown-option [value]="country.CountryCode">{{
            country.CountryName
          }}</tds-dropdown-option>
          }
        </tds-dropdown>

        <tds-dropdown
          #cityRef
          ngDefaultControl
          name="city"
          placeholder="Choose a City"
          size="sm"
          open-direction="down"
          (tdsChange)="getDealearsByCity('City', $event.detail.value)"
          [disabled]="cityList.length == 0"
          filter="true"
          default-value=""
          class="dropdownWid"
          filter="true"
        >
          @for (city of cityList; track $index) {
          <tds-dropdown-option [value]="city">{{ city }}</tds-dropdown-option>
          }
        </tds-dropdown>
        <tds-dropdown
          #companyRef
          ngDefaultControl
          name="company"
          placeholder="Filter by Dealer Name"
          size="sm"
          open-direction="down"
          (tdsChange)="getDealersByName('Name', $event.detail.value)"
          [disabled]="companyNameList.length == 0"
          filter="true"
          default-value=""
          class="dropdownWid"
          filter="true"
        >
          @for (companyName of companyNameList; track $index) {
          <tds-dropdown-option [value]="companyName">{{
            companyName
          }}</tds-dropdown-option>
          }
        </tds-dropdown>
        <tds-dropdown
          #serviceRef
          ngDefaultControl
          name="services"
          placeholder="Filter by Dealer Services"
          size="sm"
          open-direction="down"
          [disabled]="serviceList.length == 0"
          multiselect="true"
          (tdsChange)="getDealerByService($event.detail.value)"
          filter="true"
          default-value=""
          class="dropdownWid"
          filter="true"
        >
          @for (service of serviceList; track $index) {
          <tds-dropdown-option [value]="service">{{
            service
          }}</tds-dropdown-option>
          }
        </tds-dropdown>

        <tds-button
          aria-label="menu"
          id="my-export-button"
          type="ghost"
          size="sm"
          text="Export"
          class="dropdownWid grid col-end-6"
          disabled="{{ !enableExport || !dealerDetailsArr.length }}"
        >
        </tds-button>
      </div>
    </div>

    <tds-divider></tds-divider>
    @if(selectedItems && selectedItems.length){
    <div
      class="mt-4 ml-5 mr-2 pb-4 tds-u-flex tds-u-gap1"
      style="overflow-x: auto; position: relative"
    >
      @for(service of selectedItems; track $index;let idx=$index){
      <tds-chip size="lg">
        <span slot="label">{{ service }}</span>
        <tds-icon
          slot="suffix"
          name="cross"
          size="16px"
          on-click="removeService(idx, service)"
        ></tds-icon>
      </tds-chip>
      }
    </div>
    <tds-divider></tds-divider>

    }
  </ng-container>

  <div
    class="w-10/12 pt-4 pb-4 pl-10 pr-10 sm:pl-5 sm:pr-5 md:pl-7 md:pr-7"
    [ngClass]="isDataLoaded && this.dealer ? 'mobileOnly' : 'hidden'"
  >
    <h5 class="tds-detail-02 mb-2 mt-4">Select Company</h5>

    <tds-dropdown
      ngDefaultControl
      name="company"
      placeholder="Select a Company"
      size="sm"
      open-direction="down"
      class="inline-block"
      (tdsChange)="showDetails($event.detail.value)"
      #dealerWorkshopRef
      class="dropdownWid"
      filter="true"
    >
      @for (dealers of dealerDetailsArr; track $index) {
      <tds-dropdown-option [value]="dealers.ScaniaId">{{
        dealers.OrganizationName.LegalName.Value
      }}</tds-dropdown-option>
      }
    </tds-dropdown>
    <tds-divider></tds-divider>
  </div>

  @if(isDataLoaded){ @if(dealerDetailsArr && dealerDetailsArr.length) {
  <div class="flex ml-4 mr-0">
    <div class="flex flex-col left-list desktopOnly">
      @for(dealer of dealerDetailsArr; track $index) {
      <div
        class="p-0 m-0 hover:bg-[#edeff3]"
        [ngClass]="isSelected(dealer) ? 'highlighted' : ''"
        (click)="showDetails(dealer.ScaniaId)"
      >
        @if(dealer?.OrganizationName?.LegalName?.Value){
        <p class="tds-headline-07 mb-0 py-2 px-2">
          {{ dealer.OrganizationName.LegalName.Value }}
          @if(dealer?.DealerId){
          {{ "- " + dealer.DealerId }}
          }
        </p>
        @if(dealer?.VisitingAddress?.PostalAddress?.PhysicalAddress?.Street?.StreetName?.Value
        || dealer?.VisitingAddress?.PostalAddress?.PhysicalAddress?.PostalCode
        ||
        dealer?.VisitingAddress?.PostalAddress?.PhysicalAddress?.City?.Value){
        <div class="px-2 tds-text-grey-700">
          @if(dealer?.VisitingAddress?.PostalAddress?.PhysicalAddress?.Street?.StreetName?.Value){
          <span class="tds-detail-05">
            {{
              dealer.VisitingAddress.PostalAddress.PhysicalAddress.Street
                .StreetName.Value + ","
            }}
          </span>
          }
          @if(dealer?.VisitingAddress?.PostalAddress?.PhysicalAddress?.PostalCode){
          <span class="tds-detail-05">
            {{
              dealer.VisitingAddress.PostalAddress.PhysicalAddress.PostalCode +
                ","
            }}
          </span>
          }
          @if(dealer?.VisitingAddress?.PostalAddress?.PhysicalAddress?.City?.Value){
          <span class="tds-detail-05 px-2 tds-text-grey-700">
            {{
              dealer.VisitingAddress.PostalAddress.PhysicalAddress.City.Value
            }}
          </span>
          }
        </div>
        }
        <div class="px-2 pb-4 pt-0 tds-text-grey-700">
          <span class="tds-detail-05">
            {{
              dealer?.ContactPerson[0]?.ContactAddress?.MobilePhoneNumber
                ?.SubscriberNumber
            }}
          </span>
        </div>
        <tds-divider></tds-divider>
        }
      </div>
      }
    </div>
    <div class="flex flex-col px-4 pr-10 right-details">
      @if(dealer?.OrganizationName?.LegalName?.Value){
      <h5 class="tds-headline-05 px-1 my-3">
        {{ dealer.OrganizationName.LegalName.Value }}
        @if(dealer?.DealerId){
        {{ "- " + dealer.DealerId }}
        }
      </h5>

      } @if(dealer){
      <div class="flex wrapFlex">
        <div class="w-full">
          <tds-divider></tds-divider>
          <h5 class="tds-detail-02 mb-2 mt-4">General info</h5>
          @if(dealer?.VisitingAddress?.ElectronicMailAddress ||
          dealer?.VisitingAddress?.UniformResourceLocator ||
          dealer?.VisitingAddress?.PostalAddress?.PhysicalAddress?.Coordinates?.Latitude
          ||
          dealer?.VisitingAddress?.PostalAddress?.PhysicalAddress?.Coordinates?.Longitude
          || dealer?.OpeningHours ) {
          @if(dealer?.VisitingAddress?.ElectronicMailAddress){
          <div class="flex">
            <div
              class="w-2/5 sm:w-1/5 md:w-1/5 lg:w-2/5 xl:w-2/5 2xl:w-2/5 3xl:w-2/5"
            >
              <div>
                <label class="inline-block">Email:</label>
              </div>
            </div>
            <div
              class="w-1/2 sm:w-1/2 md:w-3/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 3xl:w-3/5"
            >
              <tds-link>
                <a
                  href="mailto:{{
                    dealer.VisitingAddress.ElectronicMailAddress
                  }}"
                  class="tds-text-grey-700 text-wrap"
                >
                  {{ dealer.VisitingAddress.ElectronicMailAddress }}</a
                >
              </tds-link>
            </div>
          </div>
          } @if( dealer?.VisitingAddress?.UniformResourceLocator){
          <div class="flex pt-2">
            <div
              class="w-2/5 sm:w-1/5 md:w-1/5 lg:w-2/5 xl:w-2/5 2xl:w-2/5 3xl:w-2/5"
            >
              <div>
                <label class="inline-block">Homepage:</label>
              </div>
            </div>
            <div
              class="w-1/2 sm:w-1/2 md:w-3/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 3xl:w-3/5"
            >
              <tds-link>
                <a
                  href="{{
                    getUrl(dealer.VisitingAddress.UniformResourceLocator)
                  }}"
                  target="_blank"
                  class="tds-text-grey-700 text-wrap"
                >
                  {{ dealer.VisitingAddress.UniformResourceLocator }}</a
                >
              </tds-link>
            </div>
          </div>
          } @if(
          dealer?.VisitingAddress?.PostalAddress?.PhysicalAddress?.Coordinates?.Latitude){
          <div class="flex pt-2">
            <div
              class="w-2/5 sm:w-1/5 md:w-1/5 lg:w-2/5 xl:w-2/5 2xl:w-2/5 3xl:w-2/5"
            >
              <div>
                <label class="inline-block">Latitude:</label>
              </div>
            </div>
            <div class="w-3/5">
              <div>
                <span style="color: green">
                  {{
                    dealer.VisitingAddress.PostalAddress.PhysicalAddress
                      .Coordinates.Latitude
                  }}
                </span>
              </div>
            </div>
          </div>
          } @if(
          dealer?.VisitingAddress?.PostalAddress?.PhysicalAddress?.Coordinates?.Longitude){
          <div class="flex pt-2">
            <div
              class="w-2/5 sm:w-1/5 md:w-1/5 lg:w-2/5 xl:w-2/5 2xl:w-2/5 3xl:w-2/5"
            >
              <div>
                <label class="inline-block">Longitude:</label>
              </div>
            </div>
            <div class="w-3/5">
              <div>
                <span style="color: green">
                  {{
                    dealer.VisitingAddress.PostalAddress.PhysicalAddress
                      .Coordinates.Longitude
                  }}
                </span>
              </div>
            </div>
          </div>
          }

          <div class="flex pt-5 pb-7">
            <div
              class="w-2/4 sm:w-1/5 md:w-1/5 lg:w-2/4 xl:w-2/4 2xl:w-2/4 3xl:w-2/4"
            >
              <div>
                <label class="inline-block">Business Hours:</label>
              </div>
            </div>
            <div class="text-wrap w-9/12">
              <app-opening-hours
                [openingHours]="dealer?.OpeningHours"
              ></app-opening-hours>
            </div>
          </div>
          }@else {
          <a class="tds-text-grey-700"> No information available</a>

          }

          <tds-divider></tds-divider>
          <h5 class="tds-detail-02 mb-2 mt-4">Address</h5>
          <div class="flex flex-wrap mt-5">
            <div
              class="w-2/5 sm:w-1/5 md:w-1/5 lg:w-2/5 xl:w-2/5 2xl:w-2/5 3xl:w-2/5"
            >
              @if(dealer?.VisitingAddress){
              <div>
                <label class="inline-block">Visiting Address:</label>
              </div>
              }
            </div>
            <div
              class="w-1/2 sm:w-1/2 md:w-3/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 3xl:w-3/5"
            >
              @if(dealer?.VisitingAddress?.PostalAddress?.PhysicalAddress?.Street?.StreetName?.Value){
              <p class="tds-detail-03 mb-1 mt-0">
                {{
                  dealer.VisitingAddress.PostalAddress.PhysicalAddress.Street
                    .StreetName.Value
                }}
              </p>
              }
              @if(dealer?.VisitingAddress?.PostalAddress?.PhysicalAddress?.PostalCode){
              <p class="tds-detail-03 mt-1 mb-1">
                {{
                  dealer.VisitingAddress.PostalAddress.PhysicalAddress
                    .PostalCode
                }}
              </p>
              }
              @if(dealer?.VisitingAddress?.PostalAddress?.PhysicalAddress?.City?.Value){
              <p class="tds-detail-03 mt-1 mb-1">
                {{
                  dealer.VisitingAddress.PostalAddress.PhysicalAddress.City
                    .Value
                }}
              </p>
              }
            </div>
          </div>
          <div class="flex mt-5">
            <div
              class="w-2/5 sm:w-1/5 md:w-1/5 lg:w-2/5 xl:w-2/5 2xl:w-2/5 3xl:w-2/5"
            >
              @if(dealer?.MailingAddress){
              <div>
                <label class="inline-block">Mailing Address:</label>
              </div>
              }
            </div>
            <div
              class="w-1/2 sm:w-1/2 md:w-3/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 3xl:w-3/5"
            >
              @if(dealer?.MailingAddress?.PostalAddress?.PhysicalAddress?.Street?.StreetName?.Value){
              <p class="tds-detail-03 mb-1 mt-0">
                {{
                  dealer.MailingAddress.PostalAddress.PhysicalAddress.Street
                    .StreetName.Value
                }}
              </p>
              }
              @if(dealer?.MailingAddress?.PostalAddress?.PhysicalAddress?.PostalCode){
              <p class="tds-detail-03 mt-1 mb-1">
                {{
                  dealer.MailingAddress.PostalAddress.PhysicalAddress.PostalCode
                }}
              </p>
              }
              @if(dealer?.MailingAddress?.PostalAddress?.PhysicalAddress?.City?.Value){
              <p class="tds-detail-03 mt-1 mb-1">
                {{
                  dealer.MailingAddress.PostalAddress.PhysicalAddress.City.Value
                }}
              </p>
              }
            </div>
          </div>
        </div>
        <div
          class="w-full pl-0 sm:pl-0 lg:pl-5 md:pl-0 xl:pl-5 2xl:pl-5 3xl:pl-5"
        >
          <tds-accordion hideLastBorder="true">
            <tds-accordion-item
              expanded="true"
              class="numbersAccordion accordionItem"
            >
              <h5 slot="header" class="tds-detail-02 mb-2 mt-4">
                Phone Numbers
              </h5>
              <div class="pb-4 pr-3">
                <app-fax-phone [category]="dealer"></app-fax-phone>
              </div>
            </tds-accordion-item>

            @if(dealer?.OpeningHoursComments) {
            <tds-accordion-item class="businessHoursAccordion accordionItem">
              <h5 slot="header" class="tds-detail-02 mb-2 mt-4">
                Business Hours Details
              </h5>
              <div class="pb-4 pr-3">
                <div class="flex">
                  <app-opening-hours
                    [openingHours]="dealer?.OpeningHours"
                    [openingHourComments]="dealer?.OpeningHoursComments"
                  ></app-opening-hours>
                </div>
              </div>
            </tds-accordion-item>
            }

            <tds-accordion-item
              style="border: none !important"
              class="accordionItem"
            >
              <h5 slot="header" class="tds-detail-02 mb-2 mt-4 ml-0 pl-0">
                Products & Services
              </h5>
              @if ("dealer.ProvidedServices.length != 0; else noService") {
              <ul style="padding-left: 1rem; margin: 0 !important">
                @for (services of dealer?.ProvidedServices; track $index) {
                <li>{{ services.DealerServiceName }}</li>
                @if (services?.SubServices) {
                <ul>
                  @for (subService of services?.SubServices; track $index) {
                  <li>{{ subService.DealerServiceName }}</li>
                  }
                </ul>
                } }
              </ul>
              }
              <ng-template #noService
                >No products and services available</ng-template
              >
            </tds-accordion-item>

            @if(dealer?.InvoiceProfile?.BankName){
            <tds-accordion-item class="bankAccordion accordionItem">
              <h5 slot="header" class="tds-detail-02 mb-2 mt-4">
                Bank Details
              </h5>
              @if(dealer?.InvoiceProfile?.BankName){
              <div>
                <label class="tds-headline-07">Bank Name:</label>
                <span class="ml-2">{{ dealer.InvoiceProfile.BankName }}</span>
              </div>
              } @if(dealer?.InvoiceProfile?.IBAN){
              <div class="mt-1 mb-1">
                <label class="tds-headline-07">IBAN:</label>
                <span class="ml-2">{{ dealer.InvoiceProfile.IBAN }}</span>
              </div>
              } @if(dealer?.InvoiceProfile?.AccountNumber){
              <div class="mt-1 mb-1 accordion-overflow">
                <label class="tds-headline-07">Bank Account Number:</label>
                <span class="ml-2">{{
                  dealer.InvoiceProfile.AccountNumber
                }}</span>
              </div>
              } @if(dealer?.InvoiceProfile?.SWIFT_BIC){
              <div class="mt-1 mb-1">
                <label class="tds-headline-07">Swift BIC Code:</label>
                <span class="ml-2">{{ dealer.InvoiceProfile.SWIFT_BIC }}</span>
              </div>
              } @if(dealer?.InvoiceProfile?.BankBranch){
              <div class="mt-1 mb-1">
                <label class="tds-headline-07">Bank City:</label>
                <span class="ml-2">
                  {{ dealer.InvoiceProfile.BankBranch }}</span
                >
              </div>
              }
            </tds-accordion-item>
            }
          </tds-accordion>
        </div>
      </div>
      @if (dealer?.ContactPerson && dealer?.ContactPerson.length) {
      <div style="width: 100%; padding-right: 20px; padding-top: 20px">
        <tds-divider
          orientation="horizontal"
          class="tds-text-blue-800"
        ></tds-divider>
      </div>
      }
      <div class="flex p-3 pl-0">
        @if (dealer?.ContactPerson && dealer?.ContactPerson.length) {
        <div class="w-full">
          <h5 class="tds-detail-02 mb-4 mt-4">Contact Person</h5>
          <div class="table-scroll">
            <tds-table
              vertical-dividers="false"
              responsive
              compact-design="false"
              no-min-width
            >
              <tds-table-header>
                <tds-header-cell
                  cell-key="position"
                  cell-value="Position/Responsible"
                  class="var(--tds-table-header-background)"
                ></tds-header-cell>
                <tds-header-cell
                  cell-key="name"
                  cell-value="Name"
                  class="var(--tds-table-header-background)"
                ></tds-header-cell>
                <tds-header-cell
                  cell-key="email"
                  cell-value="Email"
                  class="var(--tds-table-header-background)"
                ></tds-header-cell>
                <tds-header-cell
                  cell-key="phone"
                  cell-value="Phone"
                  class="var(--tds-table-header-background)"
                ></tds-header-cell>
                <tds-header-cell
                  cell-key="mobile"
                  cell-value="Mobile"
                  class="var(--tds-table-header-background)"
                ></tds-header-cell>
                <!-- <tds-header-cell
                    cell-key="fax"
                    cell-value="Fax"
                    class="var(--tds-table-header-background)"
                  ></tds-header-cell> -->
                <tds-header-cell
                  cell-key="others"
                  cell-value="Others"
                  class="var(--tds-table-header-background)"
                ></tds-header-cell>
              </tds-table-header>
              <tds-table-body>
                @for (contact of dealer?.ContactPerson; track $index) {
                <tds-table-body-row>
                  <tds-body-cell>{{ contact?.PersonRole }}</tds-body-cell>
                  <tds-body-cell class="text-nowrap">{{
                    contact?.PersonName?.FullName
                  }}</tds-body-cell>
                  <tds-body-cell>
                    <a
                      class="tds-text-grey-700 text-nowrap"
                      href="mailto:{{
                        contact?.ContactAddress?.ElectronicMailAddress
                      }}"
                    >
                      {{ contact?.ContactAddress?.ElectronicMailAddress }}
                    </a></tds-body-cell
                  >
                  <tds-body-cell
                    ><a
                      class="tds-text-grey-700 text-nowrap"
                      href="tel:{{
                        contact?.ContactAddress?.FixedPhoneNumber
                          ?.SubscriberNumber
                      }}"
                    >
                      {{
                        contact?.ContactAddress?.FixedPhoneNumber
                          ?.SubscriberNumber
                      }}
                    </a></tds-body-cell
                  >
                  <tds-body-cell>
                    <a
                      class="tds-text-grey-700 text-nowrap"
                      href="tel:{{
                        contact?.ContactAddress?.MobilePhoneNumber
                          ?.SubscriberNumber
                      }}"
                    >
                      {{
                        contact?.ContactAddress?.MobilePhoneNumber
                          ?.SubscriberNumber
                      }}
                    </a></tds-body-cell
                  >
                  <tds-body-cell>
                    <a
                      class="tds-text-grey-700 text-nowrap"
                      href="tel:{{ contact?.Others }}"
                    >
                      {{ contact?.Others }}
                    </a></tds-body-cell
                  >
                </tds-table-body-row>
                }
              </tds-table-body>
            </tds-table>
          </div>
        </div>

        }
      </div>
      }
    </div>
  </div>
  } @else{
  <div
    class="flex h-full justify-center items-center"
    style="height: 40vh !important"
  >
    <div>
      <h3 class="tds-headline-03">No Information Available</h3>
    </div>
  </div>
  }}
</div>

<tds-popover-menu
  id="my-export-menu"
  placement="bottom-start"
  selector="#my-export-button"
>
  <tds-popover-menu-item>
    <p class="p-2" (click)="export('excel')">XLSX</p>
  </tds-popover-menu-item>
  <tds-popover-menu-item>
    <p class="p-2" (click)="export('csv')">CSV</p>
  </tds-popover-menu-item>
</tds-popover-menu>
