import { Component, OnInit, ViewChild } from '@angular/core';
import { DistributorsService } from '../services/distributors.service';
import { CountryList } from '../model/CountryList.model';
import { TegelModule } from '@scania/tegel-angular-17';
import { UtilsService } from '../utils/utils.service';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { OpeningHoursComponent } from '../common/opening-hours/opening-hours.component';
import { ExportService } from '../services/export.service';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';

@Component({
  selector: 'app-distributors',
  standalone: true,
  imports: [TegelModule, TitleCasePipe, OpeningHoursComponent, CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  templateUrl: './distributors.component.html',
  styleUrl: './distributors.component.css',
})
export class DistributorsComponent {
  constructor(
    private distributorService: DistributorsService,
    private utilServices: UtilsService,
    private exportService: ExportService
  ) {}
  countryLists: CountryList[] = [];
  distributorDetailsArr: any[] = [];
  isDataLoaded: boolean = false;
  enableExport = false;
  selectedValues: any = [];
  selectedDetails: boolean = false;
  distributor: any;
  @ViewChild('distributorRef', { static: true })
  distributorRef!: HTMLTdsDropdownElement;

  ngOnInit() {
    this.distributorService.getDistributorsCountry().subscribe((res: any) => {
      if (res && res?.DistributorCountry) {
        this.countryLists = res.DistributorCountry;
        this.countryLists.sort((a, b) =>
          a.CountryName.localeCompare(b.CountryName)
        );
      } else {
        this.countryLists = [];
      }
    });
  }

  showDetails(scaniaId: string) {
    let data = this.distributorDetailsArr.find(
      (item: any) => item.ScaniaId === scaniaId
    );
    if (data) {
      this.distributor = data;
      this.selectedDetails = true;
    }
    if (window.innerWidth > 992)
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  isSelected(data: any) {
    return this.distributor.ScaniaId === data.ScaniaId;
  }

  changeCountry(columnName: string, country: string) {
    this.distributorService
      .getDistributorDetailsByCountry(country)
      .subscribe((res: any) => {
        try {
          if (res && res.length > 0) {
            this.isDataLoaded = true;
            this.distributorDetailsArr = res;
            this.distributor = this.distributorDetailsArr[0];
            setTimeout(() => {
              this.distributorRef?.setValue(this.distributor?.ScaniaId);
            }, 200);

            this.enableExport = true;
            this.selectedValues[columnName] = country;
          } else {
            this.isDataLoaded = false;
            this.enableExport = false;
            console.error('No Data Available for the selected Country..');
          }
        } catch (error) {
          console.error('No Data for selected Country:', error);
        }
      });
  }

  getFormattedTitleCase(item: any) {
    const convertedString = item.join(', ').replace(/_/g, ' ');
    const formattedString = this.utilServices.formatString(convertedString);
    return formattedString;
  }

  async export(type: any) {
    //let type = event?.detail?.value;
    let id: any = await this.exportService.fetchCateegoryDataByName(
      'Distributor'
    )?.Id;
    this.createExportFilterQuery();
    const request = {
      type: type,
      categoryid: id,
      filterData: JSON.stringify(this.createExportFilterQuery()),
    };
    this.exportService.exportData(request).subscribe((res: any) => {
      if (res != undefined) {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        let a = document.createElement('a');
        if (type === 'excel') {
          a.download =
            'sisexport-distributor-' + this.getDateFormat() + '.xlsx';
        } else {
          a.download =
            'sisexport-distributor-' + this.getDateFormat() + '.' + type;
        }
        a.href = url;
        a.click();
        //this.selectedValues = [];
      }
    });
  }

  async exportfortest(event: any) {
    let type = event?.detail?.value;
    let id: any = await this.exportService.fetchCateegoryDataByName(
      'Distributor'
    )?.Id;
    this.createExportFilterQuery();
    const request = {
      type: type,
      categoryid: id,
      filterData: JSON.stringify(this.createExportFilterQuery()),
    };
    this.exportService.exportData(request).subscribe((res: any) => {
      //this.selectedValues = [];
    });
  }

  getDateFormat() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}${month}${day}`;
    return formattedDate;
  }
  createExportFilterQuery() {
    let reqarray = [];
    if (this.selectedValues) {
      for (let key in this.selectedValues) {
        let obj = { field: '', op: 'bw', data: '' };
        obj.field = key;
        obj.data = this.selectedValues[key];
        reqarray.push(obj);
      }
    }
    return reqarray;
  }

  getUrl(url: string) {
    return this.utilServices.getUrl(url);
  }
}

export interface DropdownEvent {
  name: string;
  value: string;
}
