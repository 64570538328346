<div class="mainContainer">
  <ng-container>
    <div class="pt-4 pb-4 pl-10 pr-10 sm:pl-5 sm:pr-5 md:pl-7 md:pr-7">
      <span class="tds-text-grey-958 font-bold text-base">Filter By:</span>
      <div
        class="grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5 inline-grid w-11/12 ml-5 filterGrid"
      >
        <tds-dropdown
          ngDefaultControl
          name="country"
          placeholder="Select a Country"
          size="sm"
          open-direction="down"
          class="inline-block"
          (tdsChange)="changeCountry('Country', $event.detail.value)"
          filter="true"
        >
          @for (country of countryLists; track $index) {
          <tds-dropdown-option [value]="country.CountryCode">
            {{ country.CountryName }}
          </tds-dropdown-option>
          }
        </tds-dropdown>
        <!-- <tds-dropdown
          name="dropdown"
          size="sm"
          open-direction="down"
          placeholder="Export"
          class="w-24 grid col-end-6"
          (tdsChange)="export($event)"
          disabled="{{ !enableExport || !assistanceArr.length }}"
        >
          <tds-dropdown-option value="pdf">PDF</tds-dropdown-option>
          <tds-dropdown-option value="excel">XLSX</tds-dropdown-option>
          <tds-dropdown-option value="csv">CSV</tds-dropdown-option>
        </tds-dropdown> -->
        <tds-button
          aria-label="menu"
          id="my-export-button"
          type="ghost"
          size="sm"
          text="Export"
          class="grid col-end-6"
          disabled="{{
            !enableExport ||
              !assistanceArr.length ||
              assistanceData?.disabled === true
          }}"
        >
        </tds-button>
      </div>
    </div>
    <tds-divider></tds-divider>
  </ng-container>
  <!-- @if(isDataLoaded){@if(assistanceArr && assistanceArr.length) { @for
  (assistance of assistanceArr; track $index) {
  <div class="col p-4 pl-10 pr-10">
    <div
      class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 mt-3"
    >
      <div class="mb-2 mt-1">
        <div>
          @if(assistance?.DomicileCountry?.CountryCode){
          <label class="font-bold">Country:</label>
          <a class="tds-text-grey-700  country">
            {{ assistance?.DomicileCountry?.CountryCode }} -
            {{ assistance?.DomicileCountry?.CountryName }}
          </a>
          }
        </div>
        <div class="mt-1 mb-1">
          @if(assistance?.AssistancePhoneNumbers?.AssistanceLocalPhoneNumber?.SubscriberNumber)
          {
          <label class="font-bold">Assistance Local Phone Number:</label>
          <tds-link>
            <a
              href="{{
                assistance?.AssistancePhoneNumbers?.AssistanceLocalPhoneNumber
                  ?.SubscriberNumber
              }}"
              target="_blank"
              class="tds-text-grey-700 "
            >
              {{
                assistance?.AssistancePhoneNumbers?.AssistanceLocalPhoneNumber
                  ?.SubscriberNumber
              }}
            </a>
          </tds-link>
          }
        </div>
        <div class="mt-1 mb-1">
          @if( assistance?.AssistancePhoneNumbers
          ?.AssistanceFacsimileNumber?.SubscriberNumber ) {
          <label class="font-bold">Assistance Fax Number:</label>
          <tds-link>
            <a
              href="{{
                assistance?.AssistancePhoneNumbers?.AssistanceFacsimileNumber
                  ?.SubscriberNumber
              }}"
              target="_blank"
              class="tds-text-grey-700 "
            >
              {{
                assistance?.AssistancePhoneNumbers?.AssistanceFacsimileNumber
                  ?.SubscriberNumber
              }}
            </a>
          </tds-link>
          }
        </div>
        <div class="mt-1 mb-1">
          @if(
          assistance?.AssistancePhoneNumbers?.AssistanceInternationalPhoneNumber?.SubscriberNumber
          ){
          <label class="font-bold"
            >Assistance International Phone Number:</label
          >
          <tds-link>
            <a
              href="{{
                assistance?.AssistancePhoneNumbers
                  ?.AssistanceInternationalPhoneNumber?.SubscriberNumber
              }}"
              target="_blank"
              class="tds-text-grey-700 "
            >
              {{
                assistance?.AssistancePhoneNumbers
                  ?.AssistanceInternationalPhoneNumber?.SubscriberNumber
              }}
            </a>
          </tds-link>
          }
        </div>
      </div>
      <div class="">
        @for(visitingAddresses of assistance.VisitingAddress; track $index){
        @if(visitingAddresses?.PostalAddress?.PhysicalAddress?.Street?.StreetName?.Value){
        <h5 class="tds-headline-05 mb-2 mt-1">Visiting Address</h5>
        @if(visitingAddresses?.PostalAddress?.PhysicalAddress?.Street?.StreetName?.Value){
        <p class="tds-detail-03 mt-1 mb-1">
          {{
            visitingAddresses.PostalAddress.PhysicalAddress.Street.StreetName
              .Value
          }}
        </p>
        } @if(visitingAddresses.PostalAddress?.PhysicalAddress?.PostalCode){
        <p class="tds-detail-03 mt-1 mb-1">
          {{ visitingAddresses.PostalAddress.PhysicalAddress.PostalCode }}
        </p>
        } @if(visitingAddresses?.PostalAddress?.PhysicalAddress?.City?.Value){
        <p class="tds-detail-03 mt-1 mb-1">
          {{ visitingAddresses.PostalAddress.PhysicalAddress.City.Value }}
        </p>
        }} @else {
        <div>
          <label class="tds-headline-05 mb-2 mt-1">Visiting Address </label>
          <a class="tds-text-grey-700 ">No information available</a>
        </div>
        } }
      </div>
      <div>
        @if(assistance?.MailingAddress?.PostalAddress?.PhysicalAddress?.Street?.StreetName?.Value){
        <h5 class="tds-headline-05 mb-2 mt-1">Mailing Address</h5>
        @if(assistance?.MailingAddress?.PostalAddress?.PhysicalAddress?.Street?.StreetName?.Value){
        <p class="tds-detail-03 mt-1 mb-1">
          {{
            assistance.MailingAddress.PostalAddress.PhysicalAddress.Street
              .StreetName.Value
          }}
        </p>
        }
        @if(assistance?.MailingAddress?.PostalAddress?.PhysicalAddress?.PostalCode){
        <p class="tds-detail-03 mt-1 mb-1">
          {{
            assistance.MailingAddress.PostalAddress.PhysicalAddress.PostalCode
          }}
        </p>
        }
        @if(assistance?.MailingAddress?.PostalAddress?.PhysicalAddress?.City?.Value){
        <p class="tds-detail-03 mt-1 mb-1">
          {{
            assistance.MailingAddress.PostalAddress.PhysicalAddress.City.Value
          }}
        </p>
        }} @else {
        <div>
          <label class="tds-headline-05 mb-2 mt-1">Mailing Address </label>
          <a class="tds-text-grey-700 ">No information available</a>
        </div>
        }
      </div>
    </div>
  </div>

  @if (assistance?.WebContacts && assistance?.WebContacts.length){
  @if(assistance?.WebContacts){
  <div class="col p-1 pl-10 pr-10 full-width">
    <h5 class="tds-headline-05 mb-2 mt-1">Web Contacts</h5>
    <div class="table-scroll">
      <tds-table
        vertical-dividers="false"
        compact-design="false"
        responsive
        no-min-width
      >
        <tds-table-header>
          <tds-header-cell
            cell-key="contact-type"
            cell-value="Contact Type"
            class="var(--tds-table-header-background)"
          ></tds-header-cell>
          <tds-header-cell
            cell-key="Details"
            cell-value="Details"
            class="var(--tds-table-header-background)"
          ></tds-header-cell>
          <tds-header-cell
            cell-key="comments"
            cell-value="Comments"
            class="var(--tds-table-header-background)"
          ></tds-header-cell>
        </tds-table-header>
        <tds-table-body>
          @for (contact of assistance.WebContacts; track $index) {
          <tds-table-body-row>
            <tds-body-cell>{{ contact?.ContactTypeTitle }}</tds-body-cell>
            <tds-body-cell>
              <a class="tds-text-grey-700" href="{{ contact?.ContactText }}">
                {{ contact?.ContactText }}
              </a></tds-body-cell
            >
            <tds-body-cell>
              <a class="tds-text-grey-700">
                {{ contact?.Comment }}
              </a></tds-body-cell
            >
          </tds-table-body-row>
          }
        </tds-table-body>
      </tds-table>
    </div>
  </div>
  }} @else {
  <div class="col p-1 pl-10 pr-10 table-scroll">
    <label class="tds-headline-05 mb-2 mt-1">Web Contacts </label>
    <a class="tds-text-grey-700 "> No information available</a>
  </div>
  } @if (assistance?.Contacts?.ContactPerson &&
  assistance?.Contacts?.ContactPerson.length){
  @if(assistance?.Contacts?.ContactPerson){
  <div class="col p-4 mt-3 mb-1 pl-10 pr-10 full-width">
    <h5 class="tds-headline-05 mb-2 mt-1">Contact Persons</h5>
    <div class="table-scroll">
      <tds-table
        vertical-dividers="false"
        compact-design="false"
        responsive
        no-min-width
      >
        <tds-table-header>
          <tds-header-cell
            cell-key="position"
            cell-value="Position/Responsible"
            class="var(--tds-table-header-background)"
          >
          </tds-header-cell>
          <tds-header-cell
            cell-key="name"
            cell-value="Name"
            class="var(--tds-table-header-background)"
          >
          </tds-header-cell>
          <tds-header-cell
            cell-key="email"
            cell-value="Email"
            class="var(--tds-table-header-background)"
          >
          </tds-header-cell>
          <tds-header-cell
            cell-key="phone"
            cell-value="Phone"
            class="var(--tds-table-header-background)"
          >
          </tds-header-cell>
          <tds-header-cell
            cell-key="mobile"
            cell-value="Mobile"
            class="var(--tds-table-header-background)"
          >
          </tds-header-cell>
          <tds-header-cell
            cell-key="fax"
            cell-value="Fax"
            class="var(--tds-table-header-background)"
          >
          </tds-header-cell>
          <tds-header-cell
            cell-key="others"
            cell-value="Others"
            class="var(--tds-table-header-background)"
          >
          </tds-header-cell>
        </tds-table-header>
        <tds-table-body>
          @for (contact of assistance?.Contacts?.ContactPerson; track $index) {
          <tds-table-body-row>
            <tds-body-cell>{{ contact?.PersonRole }}</tds-body-cell>
            <tds-body-cell>{{ contact?.PersonName?.FullName }}</tds-body-cell>
            <tds-body-cell>
              <a
                class="tds-text-grey-700"
                href="mailto:{{
                  contact?.ContactAddress?.ElectronicMailAddress
                }}"
              >
                {{ contact?.ContactAddress?.ElectronicMailAddress }}
              </a>
            </tds-body-cell>
            <tds-body-cell>
              <a
                class="tds-text-grey-700 text-nowrap"
                href="tel:{{
                  contact?.ContactAddress?.FixedPhoneNumber?.SubscriberNumber
                }}"
              >
                {{
                  contact?.ContactAddress?.FixedPhoneNumber?.SubscriberNumber
                }}
              </a>
            </tds-body-cell>
            <tds-body-cell>
              <a
                class="tds-text-grey-700 text-nowrap"
                href="tel:{{
                  contact?.ContactAddress?.MobilePhoneNumber?.SubscriberNumber
                }}"
              >
                {{
                  contact?.ContactAddress?.MobilePhoneNumber?.SubscriberNumber
                }}
              </a>
            </tds-body-cell>
            <tds-body-cell>
              <a
                class="tds-text-grey-700 text-nowrap"
                href="tel:{{
                  contact?.ContactAddress?.FacsimileNumber?.SubscriberNumber
                }}"
              >
                {{ contact?.ContactAddress?.FacsimileNumber?.SubscriberNumber }}
              </a>
            </tds-body-cell>
            <tds-body-cell>
              <a class="tds-text-grey-700 text-nowrap">
                {{ contact?.Others }}
              </a>
            </tds-body-cell>
          </tds-table-body-row>
          }
        </tds-table-body>
      </tds-table>
    </div>
  </div>
  } }@else {
  <div class="col p-4 mt-3 mb-1 pl-10 pr-10">
    <label class="tds-headline-05 mb-2 mt-1">Contact Persons </label>
    <a class="tds-text-grey-700 "> No information available</a>
  </div>
  } } }} @else {
  <div
    class="flex h-full justify-center items-center"
    style="height: 40vh !important"
  >
    <div>
      <h3 class="tds-headline-03">No Information Available</h3>
    </div>
  </div>
  } -->

  <div
    class="w-10/12 pt-4 pb-4 pl-10 pr-10 sm:pl-5 sm:pr-5 md:pl-7 md:pr-7"
    [ngClass]="
      isDataLoaded && this.assistanceData?.ScaniaId ? 'mobileOnly' : 'hidden'
    "
  >
    <h5 class="tds-detail-02 mb-2 mt-4">Select Company</h5>

    <tds-dropdown
      ngDefaultControl
      name="company"
      placeholder="Select a Company"
      size="sm"
      open-direction="down"
      class="inline-block"
      (tdsChange)="showDetails($event.detail.value)"
      class="dropdownWid"
      filter="true"
      #assistanceRef
    >
      @for (assistance of assistanceArr; track $index) {
      <tds-dropdown-option [value]="assistance.ScaniaId">
        {{ assistance?.DomicileCountry?.CountryCode }} -
        {{ assistance?.DomicileCountry?.CountryName }}
      </tds-dropdown-option>
      }
    </tds-dropdown>
    <tds-divider></tds-divider>
  </div>
  @if(isDataLoaded){ @if(assistanceArr && assistanceArr.length &&
  this.assistanceData){ } @if(assistanceArr && assistanceArr.length) {
  @if(getAllFieldsInfo(this.assistanceData)){

  <div class="flex ml-4 mr-0">
    <div class="flex flex-col left-list desktopOnly">
      @for(assistance of assistanceArr; track $index) {
      <div
        class="p-0 m-0 hover:bg-[#edeff3]"
        [ngClass]="isSelected(assistance) ? 'highlighted' : ''"
        (click)="showDetails(assistance.ScaniaId)"
      >
        @if(assistance?.DomicileCountry?.CountryCode){
        <p class="tds-headline-07 mb-0 py-2 px-2">
          {{ assistance?.DomicileCountry?.CountryCode }} -
          {{ assistance?.DomicileCountry?.CountryName }}
        </p>
        <div class="px-2 tds-text-grey-700">
          @for(visitingAddresses of assistanceData.VisitingAddress; track
          $index){
          @if(visitingAddresses?.PostalAddress?.PhysicalAddress?.Street?.StreetName?.Value){
          <span class="tds-detail-05">
            {{
              visitingAddresses.PostalAddress.PhysicalAddress.Street.StreetName
                .Value + ","
            }}
          </span>
          } @if(visitingAddresses.PostalAddress?.PhysicalAddress?.PostalCode){
          <span class="tds-detail-05">
            {{
              visitingAddresses.PostalAddress.PhysicalAddress.PostalCode + ","
            }}
          </span>
          } @if(visitingAddresses?.PostalAddress?.PhysicalAddress?.City?.Value){
          <span class="tds-detail-05">
            {{ visitingAddresses.PostalAddress.PhysicalAddress.City.Value }}
          </span>
          }}
        </div>
        @if(assistance?.Contacts){
        <div class="px-2 pb-4 pt-0 tds-text-grey-700">
          <span class="tds-detail-05">
            {{
              assistance?.Contacts[0]?.ContactAddress?.MobilePhoneNumber
                ?.SubscriberNumber
            }}
          </span>
        </div>
        }
        <tds-divider></tds-divider>
        }
      </div>
      }
    </div>
    <div class="flex flex-col px-4 pr-10 right-details">
      @if(assistanceData?.DomicileCountry?.CountryCode){
      <h5 class="tds-headline-05 px-1 my-3">
        {{ assistanceData?.DomicileCountry?.CountryCode }} -
        {{ assistanceData?.DomicileCountry?.CountryName }}
      </h5>

      } @if(assistanceData){
      <div class="flex wrapFlex">
        <div class="w-full mb-5">
          <tds-divider></tds-divider>
          <h5 class="tds-detail-02 mb-2 mt-4">General info</h5>
          @if(assistanceData?.AssistancePhoneNumbers?.AssistanceLocalPhoneNumber?.SubscriberNumber
          ||
          assistanceData?.AssistancePhoneNumbers?.AssistanceInternationalPhoneNumber?.SubscriberNumber){
          @if(assistanceData?.AssistancePhoneNumbers?.AssistanceLocalPhoneNumber?.SubscriberNumber){
          <div class="flex">
            <div
              class="w-2/5 sm:w-1/5 md:w-1/5 lg:w-4/6 xl:w-4/6 2xl:w-4/6 3xl:w-4/6"
            >
              <div>
                <label class="inline-block text-wrap"
                  >Assistance Local Phone Number:</label
                >
              </div>
            </div>
            <div class="w-3/5 lg:w-2/6 xl:w-2/6 2xl:w-2/6 3xl:w-2/6 text-wrap">
              <tds-link>
                <a
                  href="href:{{
                    assistanceData?.AssistancePhoneNumbers
                      ?.AssistanceLocalPhoneNumber?.SubscriberNumber
                  }}"
                  class="tds-text-grey-700 text-nowrap"
                >
                  {{
                    assistanceData?.AssistancePhoneNumbers
                      ?.AssistanceLocalPhoneNumber?.SubscriberNumber
                  }}</a
                >
              </tds-link>
            </div>
          </div>
          } @if(
          assistanceData?.AssistancePhoneNumbers?.AssistanceInternationalPhoneNumber?.SubscriberNumber
          ){
          <div class="flex pt-1">
            <div
              class="w-2/5 sm:w-1/5 md:w-1/5 lg:w-4/6 xl:w-4/6 2xl:w-4/6 3xl:w-4/6"
            >
              <div>
                <label class="inline-block text-wrap"
                  >Assistance International Phone Number:</label
                >
              </div>
            </div>
            <div class="w-3/5 lg:w-2/6 xl:w-2/6 2xl:w-2/6 3xl:w-2/6 text-wrap">
              <tds-link>
                <a
                  href="href:{{
                    assistanceData?.AssistancePhoneNumbers
                      ?.AssistanceInternationalPhoneNumber?.SubscriberNumber
                  }}"
                  class="tds-text-grey-700 text-nowrap"
                >
                  {{
                    assistanceData?.AssistancePhoneNumbers
                      ?.AssistanceInternationalPhoneNumber?.SubscriberNumber
                  }}</a
                >
              </tds-link>
            </div>
          </div>
          } }@else {
          <a class="tds-text-grey-700"> No information available</a>

          }
          <!-- <div class="mt-5">
              <tds-divider></tds-divider>
            </div> -->
        </div>
        <div
          class="w-full pl-0 sm:pl-0 lg:pl-5 md:pl-0 xl:pl-5 2xl:pl-5 3xl:pl-5"
        >
          <tds-divider></tds-divider>
          <h5 class="tds-detail-02 mb-5 mt-4">Address</h5>
          <div class="flex flex-wrap mt-5">
            <div
              class="w-2/5 sm:w-1/5 md:w-1/5 lg:w-2/5 xl:w-2/5 2xl:w-2/5 3xl:w-2/5"
            >
              @if(assistanceData?.VisitingAddress){
              <div>
                <label class="inline-block">Visiting Address:</label>
              </div>
              }
            </div>
            <div
              class="w-1/2 sm:w-1/2 md:w-3/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 3xl:w-3/5"
            >
              @for(visitingAddresses of assistanceData.VisitingAddress; track
              $index){
              @if(visitingAddresses?.PostalAddress?.PhysicalAddress?.Street?.StreetName?.Value){
              @if(visitingAddresses?.PostalAddress?.PhysicalAddress?.Street?.StreetName?.Value){
              <p class="tds-detail-03 mt-0 mb-1">
                {{
                  visitingAddresses.PostalAddress.PhysicalAddress.Street
                    .StreetName.Value
                }}
              </p>
              }
              @if(visitingAddresses.PostalAddress?.PhysicalAddress?.PostalCode){
              <p class="tds-detail-03 mt-0 mb-1">
                {{ visitingAddresses.PostalAddress.PhysicalAddress.PostalCode }}
              </p>
              }
              @if(visitingAddresses?.PostalAddress?.PhysicalAddress?.City?.Value){
              <p class="tds-detail-03 mt-0 mb-1">
                {{ visitingAddresses.PostalAddress.PhysicalAddress.City.Value }}
              </p>
              }} @else {
              <div>
                <a class="tds-text-grey-700">No information available</a>
              </div>
              } }
            </div>
          </div>
          <div class="flex mt-5">
            <div
              class="w-2/5 sm:w-1/5 md:w-1/5 lg:w-2/5 xl:w-2/5 2xl:w-2/5 3xl:w-2/5"
            >
              @if(assistanceData?.MailingAddress){
              <div>
                <label class="inline-block">Mailing Address:</label>
              </div>
              }
            </div>
            @if(assistanceData?.MailingAddress?.PostalAddress?.PhysicalAddress?.Street?.StreetName?.Value){
            <div
              class="w-3/5w-1/2 sm:w-1/2 md:w-3/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 3xl:w-3/5"
            >
              @if(assistanceData?.MailingAddress?.PostalAddress?.PhysicalAddress?.Street?.StreetName?.Value){
              <p class="tds-detail-03 mt-0 mb-1">
                {{
                  assistanceData.MailingAddress.PostalAddress.PhysicalAddress
                    .Street.StreetName.Value
                }}
              </p>
              }
              @if(assistanceData?.MailingAddress?.PostalAddress?.PhysicalAddress?.PostalCode){
              <p class="tds-detail-03 mt-0 mb-1">
                {{
                  assistanceData.MailingAddress.PostalAddress.PhysicalAddress
                    .PostalCode
                }}
              </p>
              }
              @if(assistanceData?.MailingAddress?.PostalAddress?.PhysicalAddress?.City?.Value){
              <p class="tds-detail-03 mt-0 mb-1">
                {{
                  assistanceData.MailingAddress.PostalAddress.PhysicalAddress
                    .City.Value
                }}
              </p>
              }
            </div>
            }@else {
            <div>
              <a class="tds-text-grey-700 mt-1 mb-1"
                >No information available</a
              >
            </div>
            }
          </div>
        </div>
      </div>
      <div style="width: 100%; padding-right: 20px; padding-top: 20px">
        <tds-divider
          orientation="horizontal"
          class="tds-text-blue-800"
        ></tds-divider>
      </div>
      @if (assistanceData?.WebContacts && assistanceData?.WebContacts.length){
      @if(assistanceData?.WebContacts){
      <div class="col w-full mt-3">
        <h5 class="tds-detail-02 mb-4 mt-4">Web Contacts</h5>
        <div class="table-scroll">
          <tds-table
            vertical-dividers="false"
            compact-design="false"
            responsive
            no-min-width
          >
            <tds-table-header>
              <tds-header-cell
                cell-key="contact-type"
                cell-value="Contact Type"
                class="var(--tds-table-header-background)"
              ></tds-header-cell>
              <tds-header-cell
                cell-key="Details"
                cell-value="Details"
                class="var(--tds-table-header-background)"
              ></tds-header-cell>
              <tds-header-cell
                cell-key="comments"
                cell-value="Comments"
                class="var(--tds-table-header-background)"
              ></tds-header-cell>
            </tds-table-header>
            <tds-table-body>
              @for (contact of assistanceData.WebContacts; track $index) {
              <tds-table-body-row>
                <tds-body-cell>{{ contact?.ContactTypeTitle }}</tds-body-cell>
                <tds-body-cell>
                  <a
                    class="tds-text-grey-700"
                    href="{{ contact?.ContactText }}"
                  >
                    {{ contact?.ContactText }}
                  </a></tds-body-cell
                >
                <tds-body-cell>
                  <a class="tds-text-grey-700">
                    {{ contact?.Comment }}
                  </a></tds-body-cell
                >
              </tds-table-body-row>
              }
            </tds-table-body>
          </tds-table>
        </div>
      </div>
      }} @else {
      <div class="flex flex-col mt-3">
        <label class="tds-detail-02 font-bold mb-2">Web Contacts</label>
        <a class="tds-text-grey-700 text-nowrap"> No information available</a>
      </div>
      }
      <div style="width: 100%; padding-right: 20px; padding-top: 20px">
        <tds-divider
          orientation="horizontal"
          class="tds-text-blue-800"
        ></tds-divider>
      </div>
      <div class="flex p-3 pl-0">
        @if (assistanceData?.Contacts?.ContactPerson &&
        assistanceData?.Contacts?.ContactPerson?.length) {
        <div class="w-full">
          <h5 class="tds-detail-02 mb-4 mt-4">Contact Person</h5>
          <div class="table-scroll">
            <tds-table
              vertical-dividers="false"
              responsive
              compact-design="false"
              no-min-width
            >
              <tds-table-header>
                <tds-header-cell
                  cell-key="position"
                  cell-value="Position/Responsible"
                  class="var(--tds-table-header-background)"
                ></tds-header-cell>
                <tds-header-cell
                  cell-key="name"
                  cell-value="Name"
                  class="var(--tds-table-header-background)"
                ></tds-header-cell>
                <tds-header-cell
                  cell-key="email"
                  cell-value="Email"
                  class="var(--tds-table-header-background)"
                ></tds-header-cell>
                <tds-header-cell
                  cell-key="phone"
                  cell-value="Phone"
                  class="var(--tds-table-header-background)"
                ></tds-header-cell>
                <tds-header-cell
                  cell-key="mobile"
                  cell-value="Mobile"
                  class="var(--tds-table-header-background)"
                ></tds-header-cell>
                <!-- <tds-header-cell
                          cell-key="fax"
                          cell-value="Fax"
                          class="var(--tds-table-header-background)"
                        ></tds-header-cell> -->
                <tds-header-cell
                  cell-key="others"
                  cell-value="Others"
                  class="var(--tds-table-header-background)"
                ></tds-header-cell>
              </tds-table-header>
              <tds-table-body>
                @for (contact of assistanceData?.Contacts?.ContactPerson; track
                $index) {
                <tds-table-body-row>
                  <tds-body-cell>{{ contact?.PersonRole }}</tds-body-cell>
                  <tds-body-cell class="text-nowrap">{{
                    contact?.PersonName?.FullName
                  }}</tds-body-cell>
                  <tds-body-cell>
                    <a
                      class="tds-text-grey-700 text-nowrap"
                      href="mailto:{{
                        contact?.ContactAddress?.ElectronicMailAddress
                      }}"
                    >
                      {{ contact?.ContactAddress?.ElectronicMailAddress }}
                    </a></tds-body-cell
                  >
                  <tds-body-cell
                    ><a
                      class="tds-text-grey-700 text-nowrap"
                      href="tel:{{
                        contact?.ContactAddress?.FixedPhoneNumber
                          ?.SubscriberNumber
                      }}"
                    >
                      {{
                        contact?.ContactAddress?.FixedPhoneNumber
                          ?.SubscriberNumber
                      }}
                    </a></tds-body-cell
                  >
                  <tds-body-cell>
                    <a
                      class="tds-text-grey-700 text-nowrap"
                      href="tel:{{
                        contact?.ContactAddress?.MobilePhoneNumber
                          ?.SubscriberNumber
                      }}"
                    >
                      {{
                        contact?.ContactAddress?.MobilePhoneNumber
                          ?.SubscriberNumber
                      }}
                    </a></tds-body-cell
                  >
                  <tds-body-cell>
                    <a
                      class="tds-text-grey-700 text-nowrap"
                      href="tel:{{ contact?.Others }}"
                    >
                      {{ contact?.Others }}
                    </a></tds-body-cell
                  >
                </tds-table-body-row>
                }
              </tds-table-body>
            </tds-table>
          </div>
        </div>

        }@else {
        <div class="flex flex-col mt-3">
          <label class="tds-detail-02 font-bold mb-2">Contact Persons</label>
          <a class="tds-text-grey-700 text-nowrap"> No information available</a>
        </div>
        }
      </div>
      }
    </div>
  </div>
  }@else {
  <div
    class="flex h-full justify-center items-center"
    style="height: 40vh !important"
  >
    <div>
      <h3 class="tds-headline-03">No Information Available</h3>
    </div>
  </div>
  } }} @else {
  <div
    class="flex h-full justify-center items-center"
    style="height: 40vh !important"
  >
    <div>
      <h3 class="tds-headline-03">No Information Available</h3>
    </div>
  </div>
  }
</div>

<tds-popover-menu
  id="my-export-menu"
  placement="bottom-start"
  selector="#my-export-button"
>
  <tds-popover-menu-item>
    <p class="p-2" (click)="export('excel')">XLSX</p>
  </tds-popover-menu-item>
  <tds-popover-menu-item>
    <p class="p-2" (click)="export('csv')">CSV</p>
  </tds-popover-menu-item>
</tds-popover-menu>
