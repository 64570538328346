import { CommonModule, TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TegelModule } from '@scania/tegel-angular-17';
import { AssistanceService } from '../services/assistance.service';
import { CountryList } from '../model/CountryList.model';
import { ExportService } from '../services/export.service';

@Component({
  selector: 'app-assistance',
  standalone: true,
  imports: [TegelModule, TitleCasePipe, CommonModule],
  templateUrl: './assistance.component.html',
  styleUrl: './assistance.component.css',
})
export class AssistanceComponent implements OnInit {
  countryLists: CountryList[] = [];
  assistanceArr: any[] = [];
  isDataLoaded: boolean = true;
  selectedValues: any = [];
  enableExport = false;
  assistanceData: any;
  @ViewChild('assistanceRef', { static: true })
  assistanceRef!: HTMLTdsDropdownElement;

  constructor(
    private assistanceService: AssistanceService,
    private exportService: ExportService
  ) {}

  ngOnInit(): void {
    this.assistanceService.getAssistanceCountry().subscribe((res: any) => {
      try {
        if (res && res.length > 0) {
          this.countryLists = res;
          this.countryLists.sort((a, b) =>
            a.CountryName.localeCompare(b.CountryName)
          );
        }
      } catch (error) {
        console.error(error);
      }
    });
  }

  changeCountry(columnName: string, country: string) {
    this.assistanceService
      .getAssistanceByCountry(country)
      .subscribe((res: any) => {
        try {
          if (res && res.length > 0) {
            this.isDataLoaded = true;
            this.assistanceArr = res;
            this.assistanceData = this.assistanceArr[0];
            setTimeout(() => {
              this.assistanceRef?.setValue(this.assistanceData?.ScaniaId);
            }, 200);

            this.selectedValues = [];
            this.enableExport = true;
            this.selectedValues[columnName] = country;
          } else {
            this.isDataLoaded = false;
            this.enableExport = false;
            console.error('No Data Available for the selected Country..');
          }
        } catch (error) {
          console.error('No Data for selected Country:', error);
        }
      });
  }

  async export(type: any) {
    //let type = event?.detail?.value;
    let id: any = await this.exportService.fetchCateegoryDataByName(
      'Assistance'
    )?.Id;
    this.createExportFilterQuery();
    const request = {
      type: type,
      categoryid: id,
      filterData: JSON.stringify(this.createExportFilterQuery()),
    };
    this.exportService.exportData(request).subscribe((res: any) => {
      if (res != undefined) {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        let a = document.createElement('a');
        if (type === 'excel') {
          a.download = 'sisexport-assistance-' + this.getDateFormat() + '.xlsx';
        } else {
          a.download =
            'sisexport-assistance-' + this.getDateFormat() + '.' + type;
        }
        a.href = url;
        a.click();
        //this.selectedValues = [];
      }
    });
  }

  async exportfortest(event: any) {
    let type = event?.detail?.value;
    let id: any = await this.exportService.fetchCateegoryDataByName(
      'Assistance'
    )?.Id;
    this.createExportFilterQuery();
    const request = {
      type: type,
      categoryid: id,
      filterData: JSON.stringify(this.createExportFilterQuery()),
    };
    this.exportService.exportData(request).subscribe((res: any) => {});
  }

  showDetails(scaniaId: string) {
    let data = this.assistanceArr.find(
      (item: any) => item.ScaniaId === scaniaId
    );
    if (data) {
      this.assistanceData = data;
    }
    if (window.innerWidth > 992)
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  isSelected(data: any) {
    return this.assistanceData.ScaniaId === data.ScaniaId;
  }

  getDateFormat() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}${month}${day}`;
    return formattedDate;
  }
  createExportFilterQuery() {
    let reqarray = [];
    if (this.selectedValues) {
      for (let key in this.selectedValues) {
        let obj = { field: '', op: 'bw', data: '' };
        obj.field = key;
        obj.data = this.selectedValues[key];
        reqarray.push(obj);
      }
    }
    return reqarray;
  }

  getAllFieldsInfo(assistanceData: any) {
    if (
      assistanceData?.AssistancePhoneNumbers?.AssistanceLocalPhoneNumber
        ?.SubscriberNumber ||
      assistanceData?.AssistancePhoneNumbers?.AssistanceInternationalPhoneNumber
        ?.SubscriberNumber
    ) {
      return true;
    }
    if (
      assistanceData?.VisitingAddress &&
      assistanceData?.VisitingAddress.length
    ) {
      if (
        assistanceData.VisitingAddress.some((item: any) => {
          return item?.VisitingAddress?.PostalAddress?.PhysicalAddress?.Street
            ?.StreetName?.Value;
        })
      )
        return true;
    }

    if (
      assistanceData?.MailingAddress &&
      assistanceData?.MailingAddress.length
    ) {
      if (
        assistanceData.MailingAddress.some((item: any) => {
          return item?.MailingAddress?.PostalAddress?.PhysicalAddress?.Street
            ?.StreetName?.Value;
        })
      )
        return true;
    }
    if (assistanceData?.WebContacts && assistanceData?.WebContacts.length) {
      return true;
    }
    if (
      assistanceData?.Contacts?.ContactPerson &&
      assistanceData?.Contacts?.ContactPerson?.length
    )
      return true;

    assistanceData.disabled = true;
    return false;
  }
}

export interface DropdownEvent {
  name: string;
  value: string;
}
