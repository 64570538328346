import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation, output } from '@angular/core';
import { TegelModule } from '@scania/tegel-angular-17';
import { UpperCasePipe } from '@angular/common';
import { SubjectService } from '../../services/subject.service';
import { RouterLink, RouterModule } from '@angular/router';
import { AuthService, IdentityClaims } from '../../services/auth.service';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    TegelModule,
    UpperCasePipe,
    RouterModule,
    FontAwesomeModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class HeaderComponent {
  userUrl: string = '';
  constructor(
    private subjectService: SubjectService,
    public authService: AuthService,
    private userService: UserService
  ) {
    this.subjectService.leftMenuNavSubject.subscribe((data: boolean) => {
      this.mobileNavOpen = data;
    });
    if (this.identityClaims?.email) {
      this.userService
        .getUserImage(this.identityClaims?.email)
        .subscribe((res: any) => {
          if (res) this.userUrl = URL.createObjectURL(res);
        });
    }
  }
  title = 'SIS Internal';
  mobileNavOpen = false;

  mode: 'tds-mode-light' | 'tds-mode-dark' = 'tds-mode-light';
  modeVariant: 'tds-mode-variant-primary' | 'tds-mode-variant-secondary' =
    'tds-mode-variant-primary';
  userName = '';
  placeOfWork = '';
  faPowerOff = faPowerOff;

  toggleMobileSideMenu() {
    this.mobileNavOpen = !this.mobileNavOpen;
    this.subjectService.toggleLeftMenuNavSubject(this.mobileNavOpen);
  }

  logout() {
    return this.authService.logout();
  }

  get identityClaims(): IdentityClaims | null {
    return this.authService.identityClaims;
  }
}
