import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, retry, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import financecompanyCountries from '../../mockJson/financecompanyCountries.json';
import financecompanies from '../../mockJson/financecompaniesCountryCode.json';

import { CountryList } from '../model/CountryList.model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FinanceCompaniesService {
  constructor(private http: HttpClient) {}

  getFinanceCompanyCountry() {
    return this.http.get(
      environment.baseUrl + environment.financeService.financeCountries
    );
    // return of(financecompanyCountries);
  }

  getFinanceCompaniesDetailsByCountry(countryCode: string) {
    const params = new HttpParams().set('country-code', countryCode);

    return this.http
      .get(environment.baseUrl + environment.financeService.financeCompanies, {
        params: params,
      })
      .pipe(
        retry(3),
        map((response: any) => response['FinanceCompanies'])
      );
    // return of(financecompanies);
  }
}
