import { CommonModule, UpperCasePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import {
  NavigationEnd,
  Router,
} from '@angular/router';
import { TegelModule } from '@scania/tegel-angular-17';
import menuItems from '../../shared/menuItems.json';
import { AuthService } from '../../services/auth.service';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-sub-header',
  standalone: true,
  imports: [TegelModule, UpperCasePipe, CommonModule, FontAwesomeModule],
  templateUrl: './sub-header.component.html',
  styleUrl: './sub-header.component.css',
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class SubHeaderComponent implements OnInit {
  constructor(
    private router: Router,
    public authService: AuthService,
    private userService: UserService
  ) {
    if (this.authService.identityClaims?.email) {
      this.userService
        .getUserImage(this.authService.identityClaims?.email)
        .subscribe((res: any) => {
          if (res) this.userUrl = URL.createObjectURL(res);
        });
    }
  }
  userUrl: string = '';
  title = 'SIS Internal';
  menuItems: MenuItems[] = menuItems;
  mobileNavOpen: boolean = false;
  faPowerOff = faPowerOff;
  collapsed: boolean = false;

  navigetByUrl(item: MenuItems) {
    this.mobileNavOpen = false;
    this.menuItems.forEach((item: MenuItems) => (item.isActive = false));
    item.isActive = true;
    this.router.navigate([item.url]);
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let item = this.menuItems.find((item: any) => item.url === event.url);
        if (item && !item.isActive) {
          this.menuItems.forEach((obj: MenuItems) => (obj.isActive = false));
          item.isActive = true;
        }
      }
    });
  }

  toggleMobileSideMenu() {
    this.collapsed = !this.collapsed;
    this.mobileNavOpen = !this.mobileNavOpen;
  }

  logout() {
    return this.authService.logout();
  }
}

export interface MenuItems {
  title: string;
  url: string;
  description: string;
  isActive: boolean;
  image: string;
}
