import { ErrorHandler, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, provideRouter } from '@angular/router';
import { AppComponent } from './app.component';
import { TegelModule } from '@scania/tegel-angular-17';
import { OAuthModule } from 'angular-oauth2-oidc';
import { routes } from './app.routes';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { CustomErrorHandlerService } from './services/error-handler.service';
import { AuthInterceptor } from './shared/http-interceptor/auth.interceptor';
import { HeaderComponent } from './common/header/header.component';
import { AuthCallbackComponent } from './common/auth/auth-callback.component';
import { AuthRedirectComponent } from './common/auth/auth-redirect.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SubHeaderComponent } from './common/sub-header/sub-header.component';
import { FooterComponent } from './footer/footer.component';
import { SubjectService } from './services/subject.service';
import { environment } from '../environments/environment';
import { DashboardComponent } from './dashboard/dashboard.component';

@NgModule({
  declarations: [AppComponent, DashboardComponent],
  providers: [
    provideRouter(routes),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandlerService,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    SubjectService,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    TegelModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.baseUrl],
        sendAccessToken: true
      }
    }),
    HeaderComponent,
    SubHeaderComponent,
    RouterModule,
    FontAwesomeModule,
    FooterComponent,
    AuthCallbackComponent,
    AuthRedirectComponent
  ],
})
export class AppModule { }
