import { Component } from '@angular/core';
import menuItems from '../shared/menuItems.json';

@Component({
  selector: 'app-dashboard',
  standalone: false,
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css',
})
export class DashboardComponent {
  menuItems: MenuItems[] = menuItems;
}

export interface MenuItems {
  title: string;
  url: string;
  description: string;
  isActive: boolean;
  image: string;
}
