<div class="container dashboard-img" (wheel)="scrollFunction()">
  @if(subjectService.getLoaderSubject() | async){
  <div
    class="spinner w-screen min-h-screen fixed"
    style="z-index: 2; background-color: rgba(0, 0, 0, 0.5)"
  >
    <div
      style="
        margin: 0 auto;
        text-align: center;
        position: relative;
        margin-top: 15%;
      "
    >
      <tds-spinner size="lg" variant="standard"></tds-spinner>
    </div>
  </div>

  }
  <ng-container>
    <app-header />
  </ng-container>
  <button
    type="button"
    data-twe-ripple-init
    data-twe-ripple-color="light"
    (click)="scrollTop()"
    class="!fixed bottom-5 end-5 rounded-full p-3 text-xs font-medium uppercase text-white hover:shadow-lg opacity-100"
    style="background-color: var(--tds-blue); width: 55px; height: 55px"
    *ngIf="!HideBacktoTopBtn"
    id="btn-back-to-top"
  >
    <span class="[&>svg]:w-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="3"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
        />
      </svg>
    </span>
  </button>

  <app-sub-header
    *ngIf="!hideHeaderFooter"
    [ngClass]="{ hideMenu: hideHeader == true }"
  />

  <router-outlet />
  <app-footer *ngIf="!hideHeaderFooter" />
</div>
