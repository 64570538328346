import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TegelModule } from '@scania/tegel-angular-17';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css',
  standalone: true,
  imports: [RouterLink, TegelModule],
})
export class FooterComponent implements OnInit{ 

  constructor() {

  }
  
  ngOnInit(): void {
    const sheet = new CSSStyleSheet();
    sheet.replace("div { color: red}");
  }

}
