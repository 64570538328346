import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, retry, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import dealerCountries from '../../mockJson/dealerCountries.json';
import dealers from '../../mockJson/dealerCountryCode.json';

import { CountryList } from '../model/CountryList.model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DealersService {
  constructor(private http: HttpClient) {}

  getDealersCountry() {
    return this.http.get(
      environment.baseUrl + environment.dealersService.dealerCountries
    );
    // return of(dealerCountries);
  }

  getDealerDetailsByCountry(countryCode: string) {
    const params = new HttpParams().set('country-code', countryCode);

    return this.http
      .get(environment.baseUrl + environment.dealersService.dealers, {
        params: params,
      })
      .pipe(
        retry(3),
        map((response: any) => response['Dealers'])
      );
    // return of(dealers['Dealers']);
  }
}
