import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { retry, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AssistanceService {
  constructor(private http: HttpClient) {}

  getAssistanceCountry() {
    return this.http
      .get(
        environment.baseUrl + environment.assistanceService.assistanceCountries
      )
      .pipe(
        retry(3),
        map((response: any) => response['AssistanceCountry'])
      );

    // return of(assistanceCountries['AssistanceCountry']);
  }

  getAssistanceByCountry(countryCode: string) {
    const params = new HttpParams().set('country-code', countryCode);
    return this.http
      .get(environment.baseUrl + environment.assistanceService.assistances, {
        params: params,
      })
      .pipe(
        retry(3),
        map((response: any) => response['Assistances'])
      );

    // return of(assistance['Assistances']);
  }
}
