import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-auth-redirect',
    templateUrl: './auth-redirect.component.html',
    standalone: true
})
export class AuthRedirectComponent implements OnInit {
    constructor(private authService: AuthService, private router: Router) {}
  
    ngOnInit(): void {
      if (this.authService.isAuthenticated) {
        this.router.navigateByUrl('/');
      } else {
        this.authService.login();
      }
    }
  }