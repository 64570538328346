import { Component, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
 

@Component({
  selector: 'app-fax-phone',
  standalone: true,
  imports: [],
  templateUrl: './fax-phone.component.html',
  styleUrl: './fax-phone.component.css',
})
export class FaxPhoneComponent {
  @Input() category: any;
  isFax = environment.isFax;
}
