import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUserImage(email: string) {
    return this.http.get(
      environment.baseUrl + environment.userService.photo + '/' + email
    );
  }
}
