import {
  ActivatedRouteSnapshot,
  CanActivate,
  GuardResult,
  MaybeAsync,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { SubjectService } from '../services/subject.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private subjectService: SubjectService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): MaybeAsync<GuardResult> {
    if (this.authService.isAuthenticated) {
      this.subjectService.onReceiveTokenSubject(true);
      return true;
    } else {
      this.router.navigate(['/auth-redirect']);
      setTimeout(() => {
        this.authService.login();
      }, 1000);
      return false;
    }
  }
}
