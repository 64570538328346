import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, retry } from 'rxjs';
import { environment } from '../../environments/environment';
import CustomerWorkshopCountries from '../../mockJson/customerworkshopCountries.json';
import customerWorkshopsCountryCode from '../../mockJson/customerworkshopsCountryCode.json';
import { CountryList } from '../model/CountryList.model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private http: HttpClient) {}

  getCustomerWorkshopCountries() {
    return this.http.get(
      environment.baseUrl +
        environment.customerWorkshopService.customerWorkshopCountries
    );
    // return of(CustomerWorkshopCountries);
  }

  getcustomerWorkshopDetailsByCountry(countryCode: string) {
    const params = new HttpParams().set('country-code', countryCode);
    return this.http
      .get(
        environment.baseUrl +
          environment.customerWorkshopService.customerWorkshops,
        {
          params: params,
        }
      )
      .pipe(retry(3));
    // return of(customerWorkshopsCountryCode);
  }
}
