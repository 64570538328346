import { JsonPipe, TitleCasePipe } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UtilsService } from '../../utils/utils.service';

@Component({
  selector: 'app-opening-hours',
  standalone: true,
  imports: [TitleCasePipe, JsonPipe],
  templateUrl: './opening-hours.component.html',
  styleUrl: './opening-hours.component.css',
})
export class OpeningHoursComponent implements OnInit, OnChanges {
  constructor(private utilServices: UtilsService) {}
  @Input() openingHours: any;
  @Input() openingHourComments: string = '';

  ngOnInit(): void {
    if (this.openingHours) {
      let openingHrs = this.getOpeningHours(this.openingHours);
      this.openingHours = [];
      for (let obj in openingHrs) {
        if (obj !== 'Day' && obj !== 'Time') {
          this.openingHours.push(openingHrs[obj]);
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['openingHours'].previousValue) {
      let openingHrs = this.getOpeningHours(
        changes['openingHours'].currentValue
      );
      this.openingHours = [];
      for (let obj in openingHrs) {
        if (obj !== 'Day' && obj !== 'Time') {
          this.openingHours.push(openingHrs[obj]);
        }
      }
    }
  }

  getOpeningHours(allOpeningHours: any): any {
    if (allOpeningHours) {
      let openingHours: any =
        this.utilServices.getOpeningHours(allOpeningHours);
      let groupedData = this.getGroupedData(openingHours);
      return groupedData;
    } else return {};
  }

  getGroupedData(openingHours: any): any[] {
    let groupedData = openingHours.reduce((acc: any, item: any) => {
      if (!acc[item.Time] || acc[item.Time] == 'undefined') {
        acc[item.Time] = [];
      }
      acc[item.Time].push(item);
      return acc;
    }, {});

    return groupedData;
  }
}
