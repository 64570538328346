import { Injectable } from '@angular/core';
import { OpeningHours } from '../model/OpeningHours.model';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}
  getOpeningHours(allOpeningHours: any) {
    let openingHours: OpeningHours[] = [];

    allOpeningHours.forEach((element: any) => {
      let openingTimes: string = '';

      element.OpenTimes.forEach((timeElement: any) => {
        openingTimes +=
          timeElement.TimeFrom.substring(0, 5) +
          ' - ' +
          timeElement.TimeTo.substring(0, 5) +
          ', ';
      });

      openingTimes = openingTimes.substring(0, openingTimes.length - 2);

      element.Days.forEach((element: any) => {
        var timing = { Day: element, Time: openingTimes };
        openingHours.push(timing);
      });
    });

    return openingHours;
  }

  check_duplicate_in_array(input_array: any[]) {
    const duplicates = input_array.filter(
      (item, index) => input_array.indexOf(item) !== index
    );
    return Array.from(new Set(duplicates));
  }

  get_unique_object_array(input_array: any[], key: string): any[] {
    return input_array
      .map((item) => item[key])
      .filter((value, index, self) => self.indexOf(value) === index);
  }

  formatString(inputString: string): string {
    const words = inputString.split(', ');

    const formattedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );

    const formattedString = formattedWords.join(', ');

    return formattedString;
  }

  getUrl(url: string) {
    if (url.startsWith('http')) return url;
    else return `https://${url}`;
  }
}
