@if(category?.InvoiceReceivablesAddress?.FacsimileNumber?.SubscriberNumber ||
category?.InvoiceReceivablesAddress?.FixedPhoneNumber?.SubscriberNumber ||
category?.CustomerReceptionPhoneNumbers
?.CustomerReceptionFacsimileNumber?.SubscriberNumber ||
category?.CustomerReceptionPhoneNumbers?.CustomerReceptionPhoneNumber
?.SubscriberNumber || category?.CustomerReceptionPhoneNumbers
?.CustomerReceptionNightPhoneNumber?.SubscriberNumber||
category?.TowingPhoneNumber?.SubscriberNumber ||
category?.InvoicePayablesAddress?.FacsimileNumber?.SubscriberNumber ||
category?.TowingPhoneNumber?.FixedPhoneNumber?.SubscriberNumber ||
category?.PartsAddress?.FacsimileNumber?.SubscriberNumber ||
category?.PartsAddress?.FixedPhoneNumber?.SubscriberNumber ||
category?.SalesAddress?.FacsimileNumber?.SubscriberNumber ||
category?.SalesAddress?.FixedPhoneNumber?.SubscriberNumber ||
category?.WorkshopAddress?.FacsimileNumber?.SubscriberNumber ||
category?.WorkshopAddress?.FixedPhoneNumber?.SubscriberNumber){
<div>
  <!-- <h5 class="tds-headline-05 mb-2 mt-1">{{isFax ? 'Phone & Fax' : 'Phone Details'}}</h5> -->
  <!-- <table class="sistable table table-responsive">
    <thead>
      <tr>
        <th
          class="table-head-cell background"
          style="border-top-left-radius: 5px"
        >
          Contact Type
        </th>
        <th
          class="table-head-cell background"
          style="border-top-right-radius: 5px"
        >
          Number
        </th>
      </tr>
    </thead>
    <tbody class="table-body">
      @if(
      category?.InvoiceReceivablesAddress?.FacsimileNumber?.SubscriberNumber && isFax){
      <tr>
        <td>Fax Book Keeping</td>
        <td>
          <a
            class="tds-text-grey-700"
            href="tel: {{
              category.InvoiceReceivablesAddress.FacsimileNumber
                .SubscriberNumber
            }}"
          >
            {{
              category.InvoiceReceivablesAddress.FacsimileNumber
                .SubscriberNumber
            }}
          </a>
        </td>
      </tr>
      } @if(category?.InvoiceReceivablesAddress?.FixedPhoneNumber
      ?.SubscriberNumber){
      <tr>
        <td>Phone Book Keeping</td>
        <td>
          <a
            class="tds-text-grey-700"
            href="tel:{{
              category.InvoiceReceivablesAddress.FixedPhoneNumber
                .SubscriberNumber
            }}"
          >
            {{
              category.InvoiceReceivablesAddress.FixedPhoneNumber
                .SubscriberNumber
            }}
          </a>
        </td>
      </tr>
      }
      
      @if(category?.CustomerReceptionPhoneNumbers
      ?.CustomerReceptionFacsimileNumber?.SubscriberNumber && isFax){
      <tr>
        <td>Fax General</td>
        <td>
          <a
            class="tds-text-grey-700"
            href="tel:{{
              category.CustomerReceptionPhoneNumbers
                .CustomerReceptionFacsimileNumber.SubscriberNumber
            }}"
          >
            {{
              category.CustomerReceptionPhoneNumbers
                .CustomerReceptionFacsimileNumber.SubscriberNumber
            }}
          </a>
        </td>
      </tr>
      } 
      
      @if(
      category?.CustomerReceptionPhoneNumbers?.CustomerReceptionPhoneNumber
      ?.SubscriberNumber){
      <tr>
        <td>Phone General</td>
        <td>
          <a
            class="tds-text-grey-700"
            href="tel:{{
              category.CustomerReceptionPhoneNumbers
                .CustomerReceptionPhoneNumber.SubscriberNumber
            }}"
          >
            {{
              category.CustomerReceptionPhoneNumbers
                .CustomerReceptionPhoneNumber.SubscriberNumber
            }}
          </a>
        </td>
      </tr>
      } @if(category?.CustomerReceptionPhoneNumbers
      ?.CustomerReceptionNightPhoneNumber?.SubscriberNumber){
      <tr>
        <td>Phone Night</td>
        <td>
          <a
            class="tds-text-grey-700"
            href="tel:{{
              category.CustomerReceptionPhoneNumbers
                .CustomerReceptionNightPhoneNumber.SubscriberNumber
            }}"
          >
            {{
              category.CustomerReceptionPhoneNumbers
                .CustomerReceptionNightPhoneNumber.SubscriberNumber
            }}
          </a>
        </td>
      </tr>
      } @if(category?.TowingPhoneNumber?.SubscriberNumber){
      <tr>
        <td>Phone Towing</td>
        <td>
          <a
            class="tds-text-grey-700"
            href="tel:{{ category.TowingPhoneNumber.SubscriberNumber }}"
          >
            {{ category.TowingPhoneNumber.SubscriberNumber }}
          </a>
        </td>
      </tr>
      }
      @if(category?.InvoicePayablesAddress?.FacsimileNumber?.SubscriberNumber && isFax){
      <tr>
        <td>Fax Invoicing</td>
        <td>
          <a
            class="tds-text-grey-700"
            href="tel:{{
              category.InvoicePayablesAddress.FacsimileNumber.SubscriberNumber
            }}"
          >
            {{
              category.InvoicePayablesAddress.FacsimileNumber.SubscriberNumber
            }}
          </a>
        </td>
      </tr>
      } @if(category?.TowingPhoneNumber?.FixedPhoneNumber?.SubscriberNumber){
      <tr>
        <td>Phone Invoicing</td>
        <td>
          <a
            class="tds-text-grey-700"
            href="tel:{{
              category.TowingPhoneNumber.FixedPhoneNumber.SubscriberNumber
            }}"
          >
            {{ category.TowingPhoneNumber.FixedPhoneNumber.SubscriberNumber }}
          </a>
        </td>
      </tr>
      } @if(category?.PartsAddress?.FacsimileNumber?.SubscriberNumber && isFax){
      <tr>
        <td>Fax Parts</td>
        <td>
          <a
            class="tds-text-grey-700"
            href="tel:{{
              category.PartsAddress.FacsimileNumber.SubscriberNumber
            }}"
          >
            {{ category.PartsAddress.FacsimileNumber.SubscriberNumber }}
          </a>
        </td>
      </tr>
      } @if(category?.PartsAddress?.FixedPhoneNumber?.SubscriberNumber){
      <tr>
        <td>Phone Parts</td>
        <td>
          <a
            class="tds-text-grey-700"
            href="tel:{{
              category.PartsAddress.FixedPhoneNumber.SubscriberNumber
            }}"
          >
            {{ category.PartsAddress.FixedPhoneNumber.SubscriberNumber }}
          </a>
        </td>
      </tr>
      } @if(category?.SalesAddress?.FacsimileNumber?.SubscriberNumber && isFax){
      <tr>
        <td>Fax Sales</td>
        <td>
          <a
            class="tds-text-grey-700"
            href="tel:{{
              category.SalesAddress.FacsimileNumber.SubscriberNumber
            }}"
          >
            {{ category.SalesAddress.FacsimileNumber.SubscriberNumber }}
          </a>
        </td>
      </tr>
      } @if(category?.SalesAddress?.FixedPhoneNumber?.SubscriberNumber){
      <tr>
        <td>Phone Sales</td>
        <td>
          <a
            class="tds-text-grey-700"
            href="tel:{{
              category.SalesAddress.FixedPhoneNumber.SubscriberNumber
            }}"
          >
            {{ category.SalesAddress.FixedPhoneNumber.SubscriberNumber }}
          </a>
        </td>
      </tr>
      } @if(category?.WorkshopAddress?.FacsimileNumber?.SubscriberNumber && isFax){
      <tr>
        <td>Fax Workshop</td>
        <td>
          <a
            class="tds-text-grey-700"
            href="tel:{{
              category.WorkshopAddress.FacsimileNumber.SubscriberNumber
            }}"
          >
            {{ category.WorkshopAddress.FacsimileNumber.SubscriberNumber }}
          </a>
        </td>
      </tr>
      } @if(category?.WorkshopAddress?.FixedPhoneNumber?.SubscriberNumber){

      <tr>
        <td>Phone Workshop Day</td>
        <td>
          <a
            class="tds-text-grey-700"
            href="tel:{{
              category.WorkshopAddress.FixedPhoneNumber.SubscriberNumber
            }}"
          >
            {{ category.WorkshopAddress.FixedPhoneNumber.SubscriberNumber }}
          </a>
        </td>
      </tr>
      }
    </tbody>
  </table> -->

  @if(category?.InvoiceReceivablesAddress?.FixedPhoneNumber ?.SubscriberNumber){
  <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-2 mt-1 mb-1">
    <div>
      <div>
        <label class="inline-block whitespace-break-spaces"
          >Phone Book Keeping:</label
        >
      </div>
    </div>
    <div style="position: relative; left: 12px">
      <a
        class="tds-text-grey-700 whitespace-nowrap"
        href="tel:{{
          category.InvoiceReceivablesAddress.FixedPhoneNumber.SubscriberNumber
        }}"
      >
        {{
          category.InvoiceReceivablesAddress.FixedPhoneNumber.SubscriberNumber
        }}
      </a>
    </div>
  </div>
  } @if( category?.CustomerReceptionPhoneNumbers?.CustomerReceptionPhoneNumber
  ?.SubscriberNumber){
  <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-2 mt-1 mb-1">
    <div>
      <div>
        <label class="inline-block whitespace-break-spaces"
          >Phone General:</label
        >
      </div>
    </div>
    <div style="position: relative; left: 12px">
      <a
        class="tds-text-grey-700 whitespace-nowrap"
        href="tel:{{
          category.CustomerReceptionPhoneNumbers.CustomerReceptionPhoneNumber
            .SubscriberNumber
        }}"
      >
        {{
          category.CustomerReceptionPhoneNumbers.CustomerReceptionPhoneNumber
            .SubscriberNumber
        }}
      </a>
    </div>
  </div>
  } @if(category?.CustomerReceptionPhoneNumbers
  ?.CustomerReceptionNightPhoneNumber?.SubscriberNumber){
  <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-2 mt-1 mb-1">
    <div>
      <div>
        <label class="inline-block whitespace-break-spaces">Phone Night:</label>
      </div>
    </div>
    <div style="position: relative; left: 12px">
      <a
        class="tds-text-grey-700 whitespace-nowrap"
        href="tel:{{
          category.CustomerReceptionPhoneNumbers
            .CustomerReceptionNightPhoneNumber.SubscriberNumber
        }}"
      >
        {{
          category.CustomerReceptionPhoneNumbers
            .CustomerReceptionNightPhoneNumber.SubscriberNumber
        }}
      </a>
    </div>
  </div>
  } @if(category?.TowingPhoneNumber?.SubscriberNumber){
  <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-2 mt-1 mb-1">
    <div>
      <div>
        <label class="inline-block whitespace-break-spaces"
          >Phone Towing:</label
        >
      </div>
    </div>
    <div style="position: relative; left: 12px">
      <a
        class="tds-text-grey-700 whitespace-nowrap"
        href="tel:{{ category.TowingPhoneNumber.SubscriberNumber }}"
      >
        {{ category.TowingPhoneNumber.SubscriberNumber }}
      </a>
    </div>
  </div>
  } @if(category?.TowingPhoneNumber?.FixedPhoneNumber?.SubscriberNumber){
  <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-2 mt-1 mb-1">
    <div style="position: relative; left: 12px">
      <div>
        <label class="inline-block whitespace-break-spaces"
          >Phone Invoicing:</label
        >
      </div>
    </div>
    <div style="position: relative; left: 12px">
      <a
        class="tds-text-grey-700 whitespace-nowrap"
        href="tel:{{
          category.TowingPhoneNumber.FixedPhoneNumber.SubscriberNumber
        }}"
      >
        {{ category.TowingPhoneNumber.FixedPhoneNumber.SubscriberNumber }}
      </a>
    </div>
  </div>
  } @if(category?.PartsAddress?.FixedPhoneNumber?.SubscriberNumber){
  <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-2 mt-1 mb-1">
    <div>
      <div>
        <label class="inline-block whitespace-break-spaces">Phone Parts:</label>
      </div>
    </div>
    <div style="position: relative; left: 12px">
      <a
        class="tds-text-grey-700 whitespace-nowrap"
        href="tel:{{ category.PartsAddress.FixedPhoneNumber.SubscriberNumber }}"
      >
        {{ category.PartsAddress.FixedPhoneNumber.SubscriberNumber }}
      </a>
    </div>
  </div>
  } @if(category?.SalesAddress?.FixedPhoneNumber?.SubscriberNumber){
  <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-2 mt-1 mb-1">
    <div>
      <div>
        <label class="inline-block whitespace-break-spaces">Phone Sales:</label>
      </div>
    </div>
    <div style="position: relative; left: 12px">
      <a
        class="tds-text-grey-700 whitespace-nowrap"
        href="tel:{{ category.SalesAddress.FixedPhoneNumber.SubscriberNumber }}"
      >
        {{ category.SalesAddress.FixedPhoneNumber.SubscriberNumber }}
      </a>
    </div>
  </div>
  } @if(category?.WorkshopAddress?.FixedPhoneNumber?.SubscriberNumber){
  <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-2 mt-1 mb-1">
    <div>
      <div>
        <label class="inline-block whitespace-break-spaces"
          >Phone Workshop Day:</label
        >
      </div>
    </div>
    <div style="position: relative; left: 12px">
      <a
        class="tds-text-grey-700 whitespace-nowrap"
        href="tel:{{
          category.WorkshopAddress.FixedPhoneNumber.SubscriberNumber
        }}"
      >
        {{ category.WorkshopAddress.FixedPhoneNumber.SubscriberNumber }}
      </a>
    </div>
  </div>
  }
</div>
} @else {
<div>
  <!-- <label class="tds-headline-05 mb-2 mt-1">{{
    isFax ? "Phone & Fax" : "Phone Details"
  }}</label> -->
  <a class="tds-text-grey-700 whitespace-nowrap"> No information available</a>
</div>
}
