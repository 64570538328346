<tds-footer class="w-full">
  <div slot="start">
    <tds-footer-group>
      <tds-footer-item>
        <a href="/">Cookies</a>
      </tds-footer-item>
      <tds-footer-item>
        <a href="/">Contact us</a>
      </tds-footer-item>
    </tds-footer-group>
  </div>
  <div slot="end">
    <tds-footer-group>
      <tds-footer-item>
        <a
          href="https://www.facebook.com/ScaniaGroup"
          class="facebook social"
          target="_blank"
        >
        </a>
      </tds-footer-item>
      <tds-footer-item>
        <a
          href="https://www.instagram.com/ScaniaGroup"
          class="insta social"
          target="_blank"
        >
        </a>
      </tds-footer-item>
      <tds-footer-item>
        <a
          href="https://www.linkedin.com/company/scania"
          class="linkedin social"
          target="_blank"
        >
        </a>
      </tds-footer-item>
      <tds-footer-item>
        <a
          href="https://x.com/scaniagroup
        "
          class="x social"
          target="_blank"
        >
        </a>
      </tds-footer-item>
      <tds-footer-item>
        <a
          href="https://www.youtube.com/ScaniaGroup"
          class="youtube social"
          target="_blank"
        >
        </a>
      </tds-footer-item>
    </tds-footer-group>
  </div>
</tds-footer>
