import { Component, OnInit, ViewChild } from '@angular/core';
import { FinanceCompaniesService } from '../services/finance-companies.service';
import { CountryList } from '../model/CountryList.model';
import { TegelModule } from '@scania/tegel-angular-17';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { ExportService } from '../services/export.service';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { UtilsService } from '../utils/utils.service';

@Component({
  selector: 'app-finance-companies',
  standalone: true,
  imports: [TegelModule, TitleCasePipe, CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  templateUrl: './finance-companies.component.html',
  styleUrl: './finance-companies.component.css',
})
export class FinanceCompaniesComponent {
  constructor(
    private financeCompaniesService: FinanceCompaniesService,
    private exportService: ExportService,
    private utilServices: UtilsService
  ) {}
  countryLists: CountryList[] = [];
  financeCompanyDetailsArr: any[] = [];
  isDataLoaded: boolean = false;
  enableExport = false;
  selectedValues: any = [];
  selectedDetails: boolean = false;
  financeCompany: any;
  @ViewChild('financeCompanyRef', { static: true })
  financeCompanyRef!: HTMLTdsDropdownElement;

  ngOnInit() {
    this.financeCompaniesService
      .getFinanceCompanyCountry()
      .subscribe((res: any) => {
        if (res && res?.FinanceCompanyCountry) {
          this.countryLists = res.FinanceCompanyCountry;
          this.countryLists.sort((a, b) =>
            a.CountryName.localeCompare(b.CountryName)
          );
        } else {
          this.countryLists = [];
        }
      });
  }

  changeCountry(columnName: string, country: string) {
    this.financeCompaniesService
      .getFinanceCompaniesDetailsByCountry(country)
      .subscribe((res: any) => {
        try {
          if (res && res.length > 0) {
            this.isDataLoaded = true;
            this.financeCompanyDetailsArr = res;
            this.financeCompany = this.financeCompanyDetailsArr[0];
            setTimeout(() => {
              this.financeCompanyRef?.setValue(this.financeCompany?.ScaniaId);
            }, 200);
            this.enableExport = true;
            this.selectedValues[columnName] = country;
          } else {
            this.isDataLoaded = false;
            this.enableExport = false;
            console.error('No Data Available for the selected Country..');
          }
        } catch (error) {
          console.error('No Data for selected Country:', error);
        }
      });
  }

  showDetails(scaniaId: string) {
    let data = this.financeCompanyDetailsArr.find(
      (item: any) => item.ScaniaId === scaniaId
    );
    if (data) {
      this.financeCompany = data;
      this.selectedDetails = true;
    }
    if (window.innerWidth > 992)
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  isSelected(data: any) {
    return this.financeCompany.ScaniaId === data.ScaniaId;
  }

  async export(type: any) {
    //let type = event?.detail?.value;
    let id: any = await this.exportService.fetchCateegoryDataByName(
      'FinanceCompany'
    )?.Id;
    this.createExportFilterQuery();
    const request = {
      type: type,
      categoryid: id,
      filterData: JSON.stringify(this.createExportFilterQuery()),
    };
    this.exportService.exportData(request).subscribe((res: any) => {
      if (res != undefined) {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        let a = document.createElement('a');
        if (type === 'excel') {
          a.download =
            'sisexport-financecompany-' + this.getDateFormat() + '.xlsx';
        } else {
          a.download =
            'sisexport-financecompany-' + this.getDateFormat() + '.' + type;
        }
        a.href = url;
        a.click();
        //this.selectedValues = [];
      }
    });
  }

  async exportfortest(event: any) {
    let type = event?.detail?.value;
    let id: any = await this.exportService.fetchCateegoryDataByName(
      'FinanceCompany'
    )?.Id;
    this.createExportFilterQuery();
    const request = {
      type: type,
      categoryid: id,
      filterData: JSON.stringify(this.createExportFilterQuery()),
    };
    this.exportService.exportData(request).subscribe((res: any) => {});
  }

  getDateFormat() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}${month}${day}`;
    return formattedDate;
  }
  createExportFilterQuery() {
    let reqarray = [];
    if (this.selectedValues) {
      for (let key in this.selectedValues) {
        let obj = { field: '', op: 'bw', data: '' };
        obj.field = key;
        obj.data = this.selectedValues[key];
        reqarray.push(obj);
      }
    }
    return reqarray;
  }

  getUrl(url: string) {
    return this.utilServices.getUrl(url);
  }
}

export interface DropdownEvent {
  name: string;
  value: string;
}
