import { Injectable } from '@angular/core';
import {
  OAuthService,
  AuthConfig,
  OAuthErrorEvent,
  OAuthSuccessEvent,
} from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { SubjectService } from './subject.service';

export interface IdentityClaims {
  given_name?: string;
  family_name?: string;
  email?: string;
  preferred_username?: string;
}

const authConfig: AuthConfig = {
  issuer: environment.authConfig.oauth.issuer,
  redirectUri: environment.authConfig.oauth.redirectSignIn,
  logoutUrl: '',
  postLogoutRedirectUri: environment.authConfig.oauth.redirectSignOut,
  clientId: environment.authConfig.userPoolWebClientId,
  responseType: environment.authConfig.oauth.responseType,
  scope: environment.authConfig.oauth.scope,
  showDebugInformation: true,
  strictDiscoveryDocumentValidation: false,
  oidc: true,
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private subjectService: SubjectService
  ) {
    this.oauthService.configure(authConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
    //this.oauthService.events.subscribe(e => console.log(e));

    this.oauthService.events.subscribe((event) => {
      if (event instanceof OAuthErrorEvent) {
        console.error(event);
      } else if (event instanceof OAuthSuccessEvent) {
        if (event.type === 'token_received') {
          this.subjectService.onReceiveTokenSubject(true);
          this.router.navigateByUrl('/');
        }
      } else {
        console.warn(event);
      }
    });
  }

  login() {
    this.oauthService.initLoginFlow();
  }

  logout() {
    try {
      //logout from cognito and redirect to /logout
      this.oauthService.logOut(true);
      window.location.href = `${environment.authConfig.oauth.domain}/logout?client_id=${environment.authConfig.userPoolWebClientId}&logout_uri=${environment.authConfig.oauth.redirectSignOut}`;
    } catch (error) {
      console.log(error);
    }
  }

  redirectOnCallback(): void {}
  
  get identityClaims(): IdentityClaims | null {
    let claims = this.oauthService.getIdentityClaims() as IdentityClaims;
    if (claims && !claims.email) claims.email = claims.preferred_username;
    return claims;
  }

  get isAuthenticated(): boolean {
    return this.oauthService.hasValidAccessToken();
  }
  getAuthorizationToken() {
    return this.oauthService.getAccessToken();
  }
  getIdToken() {
    return this.oauthService.getIdToken();
  }
}
