import { Routes } from '@angular/router';
import { DealersWorkshopsComponent } from './dealers-workshops/dealers-workshops.component';
import { DistributorsComponent } from './distributors/distributors.component';
import { AssistanceComponent } from './assistance/assistance.component';
import { CustomerWorkshopsComponent } from './customer-workshops/customer-workshops.component';
import { FinanceCompaniesComponent } from './finance-companies/finance-companies.component';
import { ProductionUnitsComponent } from './production-units/production-units.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NotFoundComponent } from '././common/not-found/not-found.component';
import { AuthCallbackComponent } from './common/auth/auth-callback.component';
import { AuthRedirectComponent } from './common/auth/auth-redirect.component';
import { LogoutMsgComponent } from './common/auth/logout-msg.component';
import { AuthGuard } from './shared/auth.guard';

export const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    data: { hideHeader: true },
    canActivate: [AuthGuard],
  },
  {
    path: 'dealersWorkshops',
    component: DealersWorkshopsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'distributors',
    component: DistributorsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'assistance',
    component: AssistanceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'customerWorkshops',
    component: CustomerWorkshopsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'financeCompanies',
    component: FinanceCompaniesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'productionUnits',
    component: ProductionUnitsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'auth-callback',
    component: AuthCallbackComponent,
    data: { hideHeaderFooter: true },
  },
  {
    path: 'auth-redirect',
    component: AuthRedirectComponent,
    data: { hideHeaderFooter: true },
  },
  {
    path: 'logout',
    component: LogoutMsgComponent,
    data: { hideHeaderFooter: true },
  },
  { path: '**', component: NotFoundComponent },
];
