import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, APP_INITIALIZER, NgModule } from '@angular/core';
import { __decorate } from 'tslib';
import { fromEvent } from 'rxjs';
import { defineCustomElements } from '@scania/tegel/loader';

/* eslint-disable */
/* tslint:disable */
const _c0 = ["*"];
const proxyInputs = (Cmp, inputs) => {
  const Prototype = Cmp.prototype;
  inputs.forEach(item => {
    Object.defineProperty(Prototype, item, {
      get() {
        return this.el[item];
      },
      set(val) {
        this.z.runOutsideAngular(() => this.el[item] = val);
      },
      /**
       * In the event that proxyInputs is called
       * multiple times re-defining these inputs
       * will cause an error to be thrown. As a result
       * we set configurable: true to indicate these
       * properties can be changed.
       */
      configurable: true
    });
  });
};
const proxyMethods = (Cmp, methods) => {
  const Prototype = Cmp.prototype;
  methods.forEach(methodName => {
    Prototype[methodName] = function () {
      const args = arguments;
      return this.z.runOutsideAngular(() => this.el[methodName].apply(this.el, args));
    };
  });
};
const proxyOutputs = (instance, el, events) => {
  events.forEach(eventName => instance[eventName] = fromEvent(el, eventName));
};
const defineCustomElement = (tagName, customElement) => {
  if (customElement !== undefined && typeof customElements !== 'undefined' && !customElements.get(tagName)) {
    customElements.define(tagName, customElement);
  }
};
// tslint:disable-next-line: only-arrow-functions
function ProxyCmp(opts) {
  const decorator = function (cls) {
    const {
      defineCustomElementFn,
      inputs,
      methods
    } = opts;
    if (defineCustomElementFn !== undefined) {
      defineCustomElementFn();
    }
    if (inputs) {
      proxyInputs(cls, inputs);
    }
    if (methods) {
      proxyMethods(cls, methods);
    }
    return cls;
  };
  return decorator;
}
let TdsAccordion = class TdsAccordion {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsAccordion_Factory(t) {
      return new (t || TdsAccordion)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsAccordion,
      selectors: [["tds-accordion"]],
      inputs: {
        hideLastBorder: "hideLastBorder",
        modeVariant: "modeVariant"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsAccordion_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsAccordion = __decorate([ProxyCmp({
  inputs: ['hideLastBorder', 'modeVariant']
})], TdsAccordion);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsAccordion, [{
    type: Component,
    args: [{
      selector: 'tds-accordion',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['hideLastBorder', 'modeVariant']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsAccordionItem = class TdsAccordionItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsToggle']);
  }
  static {
    this.ɵfac = function TdsAccordionItem_Factory(t) {
      return new (t || TdsAccordionItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsAccordionItem,
      selectors: [["tds-accordion-item"]],
      inputs: {
        disabled: "disabled",
        expandIconPosition: "expandIconPosition",
        expanded: "expanded",
        header: "header",
        paddingReset: "paddingReset"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsAccordionItem_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsAccordionItem = __decorate([ProxyCmp({
  inputs: ['disabled', 'expandIconPosition', 'expanded', 'header', 'paddingReset'],
  methods: ['toggleAccordionItem']
})], TdsAccordionItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsAccordionItem, [{
    type: Component,
    args: [{
      selector: 'tds-accordion-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'expandIconPosition', 'expanded', 'header', 'paddingReset']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsBadge = class TdsBadge {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsBadge_Factory(t) {
      return new (t || TdsBadge)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsBadge,
      selectors: [["tds-badge"]],
      inputs: {
        hidden: "hidden",
        size: "size",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsBadge_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsBadge = __decorate([ProxyCmp({
  inputs: ['hidden', 'size', 'value']
})], TdsBadge);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsBadge, [{
    type: Component,
    args: [{
      selector: 'tds-badge',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['hidden', 'size', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsBanner = class TdsBanner {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsClose']);
  }
  static {
    this.ɵfac = function TdsBanner_Factory(t) {
      return new (t || TdsBanner)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsBanner,
      selectors: [["tds-banner"]],
      inputs: {
        bannerId: "bannerId",
        header: "header",
        hidden: "hidden",
        icon: "icon",
        subheader: "subheader",
        variant: "variant"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsBanner_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsBanner = __decorate([ProxyCmp({
  inputs: ['bannerId', 'header', 'hidden', 'icon', 'subheader', 'variant'],
  methods: ['hideBanner', 'showBanner']
})], TdsBanner);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsBanner, [{
    type: Component,
    args: [{
      selector: 'tds-banner',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['bannerId', 'header', 'hidden', 'icon', 'subheader', 'variant']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsBlock = class TdsBlock {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsBlock_Factory(t) {
      return new (t || TdsBlock)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsBlock,
      selectors: [["tds-block"]],
      inputs: {
        modeVariant: "modeVariant"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsBlock_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsBlock = __decorate([ProxyCmp({
  inputs: ['modeVariant']
})], TdsBlock);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsBlock, [{
    type: Component,
    args: [{
      selector: 'tds-block',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['modeVariant']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsBodyCell = class TdsBodyCell {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsBodyCell_Factory(t) {
      return new (t || TdsBodyCell)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsBodyCell,
      selectors: [["tds-body-cell"]],
      inputs: {
        cellKey: "cellKey",
        cellValue: "cellValue",
        disablePadding: "disablePadding",
        textAlign: "textAlign"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsBodyCell_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsBodyCell = __decorate([ProxyCmp({
  inputs: ['cellKey', 'cellValue', 'disablePadding', 'textAlign']
})], TdsBodyCell);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsBodyCell, [{
    type: Component,
    args: [{
      selector: 'tds-body-cell',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['cellKey', 'cellValue', 'disablePadding', 'textAlign']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsBreadcrumb = class TdsBreadcrumb {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsBreadcrumb_Factory(t) {
      return new (t || TdsBreadcrumb)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsBreadcrumb,
      selectors: [["tds-breadcrumb"]],
      inputs: {
        current: "current"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsBreadcrumb_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsBreadcrumb = __decorate([ProxyCmp({
  inputs: ['current']
})], TdsBreadcrumb);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsBreadcrumb, [{
    type: Component,
    args: [{
      selector: 'tds-breadcrumb',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['current']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsBreadcrumbs = class TdsBreadcrumbs {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsBreadcrumbs_Factory(t) {
      return new (t || TdsBreadcrumbs)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsBreadcrumbs,
      selectors: [["tds-breadcrumbs"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsBreadcrumbs_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsBreadcrumbs = __decorate([ProxyCmp({})], TdsBreadcrumbs);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsBreadcrumbs, [{
    type: Component,
    args: [{
      selector: 'tds-breadcrumbs',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsButton = class TdsButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsButton_Factory(t) {
      return new (t || TdsButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsButton,
      selectors: [["tds-button"]],
      inputs: {
        disabled: "disabled",
        fullbleed: "fullbleed",
        modeVariant: "modeVariant",
        size: "size",
        text: "text",
        type: "type",
        variant: "variant"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsButton_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsButton = __decorate([ProxyCmp({
  inputs: ['disabled', 'fullbleed', 'modeVariant', 'size', 'text', 'type', 'variant']
})], TdsButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsButton, [{
    type: Component,
    args: [{
      selector: 'tds-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'fullbleed', 'modeVariant', 'size', 'text', 'type', 'variant']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsCard = class TdsCard {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsClick']);
  }
  static {
    this.ɵfac = function TdsCard_Factory(t) {
      return new (t || TdsCard)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsCard,
      selectors: [["tds-card"]],
      inputs: {
        bodyDivider: "bodyDivider",
        bodyImg: "bodyImg",
        bodyImgAlt: "bodyImgAlt",
        cardId: "cardId",
        clickable: "clickable",
        header: "header",
        imagePlacement: "imagePlacement",
        modeVariant: "modeVariant",
        subheader: "subheader"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsCard_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsCard = __decorate([ProxyCmp({
  inputs: ['bodyDivider', 'bodyImg', 'bodyImgAlt', 'cardId', 'clickable', 'header', 'imagePlacement', 'modeVariant', 'subheader']
})], TdsCard);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsCard, [{
    type: Component,
    args: [{
      selector: 'tds-card',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['bodyDivider', 'bodyImg', 'bodyImgAlt', 'cardId', 'clickable', 'header', 'imagePlacement', 'modeVariant', 'subheader']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsCheckbox = class TdsCheckbox {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsChange', 'tdsFocus', 'tdsBlur']);
  }
  static {
    this.ɵfac = function TdsCheckbox_Factory(t) {
      return new (t || TdsCheckbox)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsCheckbox,
      selectors: [["tds-checkbox"]],
      inputs: {
        checkboxId: "checkboxId",
        checked: "checked",
        disabled: "disabled",
        indeterminate: "indeterminate",
        name: "name",
        required: "required",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsCheckbox_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsCheckbox = __decorate([ProxyCmp({
  inputs: ['checkboxId', 'checked', 'disabled', 'indeterminate', 'name', 'required', 'value'],
  methods: ['toggleCheckbox']
})], TdsCheckbox);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsCheckbox, [{
    type: Component,
    args: [{
      selector: 'tds-checkbox',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checkboxId', 'checked', 'disabled', 'indeterminate', 'name', 'required', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsChip = class TdsChip {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsChange', 'tdsClick']);
  }
  static {
    this.ɵfac = function TdsChip_Factory(t) {
      return new (t || TdsChip)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsChip,
      selectors: [["tds-chip"]],
      inputs: {
        checked: "checked",
        chipId: "chipId",
        disabled: "disabled",
        name: "name",
        size: "size",
        type: "type",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsChip_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsChip = __decorate([ProxyCmp({
  inputs: ['checked', 'chipId', 'disabled', 'name', 'size', 'type', 'value']
})], TdsChip);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsChip, [{
    type: Component,
    args: [{
      selector: 'tds-chip',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checked', 'chipId', 'disabled', 'name', 'size', 'type', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsCoreHeaderItem = class TdsCoreHeaderItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsCoreHeaderItem_Factory(t) {
      return new (t || TdsCoreHeaderItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsCoreHeaderItem,
      selectors: [["tds-core-header-item"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsCoreHeaderItem_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsCoreHeaderItem = __decorate([ProxyCmp({})], TdsCoreHeaderItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsCoreHeaderItem, [{
    type: Component,
    args: [{
      selector: 'tds-core-header-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsDatetime = class TdsDatetime {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsChange', 'tdsBlur', 'tdsFocus', 'tdsInput']);
  }
  static {
    this.ɵfac = function TdsDatetime_Factory(t) {
      return new (t || TdsDatetime)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsDatetime,
      selectors: [["tds-datetime"]],
      inputs: {
        autofocus: "autofocus",
        defaultValue: "defaultValue",
        disabled: "disabled",
        helper: "helper",
        label: "label",
        max: "max",
        min: "min",
        modeVariant: "modeVariant",
        name: "name",
        noMinWidth: "noMinWidth",
        size: "size",
        state: "state",
        type: "type",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsDatetime_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsDatetime = __decorate([ProxyCmp({
  inputs: ['autofocus', 'defaultValue', 'disabled', 'helper', 'label', 'max', 'min', 'modeVariant', 'name', 'noMinWidth', 'size', 'state', 'type', 'value'],
  methods: ['setValue']
})], TdsDatetime);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsDatetime, [{
    type: Component,
    args: [{
      selector: 'tds-datetime',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autofocus', 'defaultValue', 'disabled', 'helper', 'label', 'max', 'min', 'modeVariant', 'name', 'noMinWidth', 'size', 'state', 'type', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsDivider = class TdsDivider {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsDivider_Factory(t) {
      return new (t || TdsDivider)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsDivider,
      selectors: [["tds-divider"]],
      inputs: {
        orientation: "orientation"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsDivider_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsDivider = __decorate([ProxyCmp({
  inputs: ['orientation']
})], TdsDivider);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsDivider, [{
    type: Component,
    args: [{
      selector: 'tds-divider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['orientation']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsDropdown = class TdsDropdown {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsChange', 'tdsFocus', 'tdsBlur', 'tdsInput']);
  }
  static {
    this.ɵfac = function TdsDropdown_Factory(t) {
      return new (t || TdsDropdown)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsDropdown,
      selectors: [["tds-dropdown"]],
      inputs: {
        defaultValue: "defaultValue",
        disabled: "disabled",
        error: "error",
        filter: "filter",
        helper: "helper",
        label: "label",
        labelPosition: "labelPosition",
        modeVariant: "modeVariant",
        multiselect: "multiselect",
        name: "name",
        noResultText: "noResultText",
        normalizeText: "normalizeText",
        openDirection: "openDirection",
        placeholder: "placeholder",
        size: "size"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsDropdown_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsDropdown = __decorate([ProxyCmp({
  inputs: ['defaultValue', 'disabled', 'error', 'filter', 'helper', 'label', 'labelPosition', 'modeVariant', 'multiselect', 'name', 'noResultText', 'normalizeText', 'openDirection', 'placeholder', 'size'],
  methods: ['reset', 'setValue', 'removeValue', 'close']
})], TdsDropdown);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsDropdown, [{
    type: Component,
    args: [{
      selector: 'tds-dropdown',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['defaultValue', 'disabled', 'error', 'filter', 'helper', 'label', 'labelPosition', 'modeVariant', 'multiselect', 'name', 'noResultText', 'normalizeText', 'openDirection', 'placeholder', 'size']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsDropdownOption = class TdsDropdownOption {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsSelect', 'tdsFocus', 'tdsBlur']);
  }
  static {
    this.ɵfac = function TdsDropdownOption_Factory(t) {
      return new (t || TdsDropdownOption)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsDropdownOption,
      selectors: [["tds-dropdown-option"]],
      inputs: {
        disabled: "disabled",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsDropdownOption_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsDropdownOption = __decorate([ProxyCmp({
  inputs: ['disabled', 'value'],
  methods: ['setSelected']
})], TdsDropdownOption);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsDropdownOption, [{
    type: Component,
    args: [{
      selector: 'tds-dropdown-option',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsFolderTab = class TdsFolderTab {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsFolderTab_Factory(t) {
      return new (t || TdsFolderTab)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsFolderTab,
      selectors: [["tds-folder-tab"]],
      inputs: {
        disabled: "disabled"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsFolderTab_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsFolderTab = __decorate([ProxyCmp({
  inputs: ['disabled']
})], TdsFolderTab);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsFolderTab, [{
    type: Component,
    args: [{
      selector: 'tds-folder-tab',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsFolderTabs = class TdsFolderTabs {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsChange']);
  }
  static {
    this.ɵfac = function TdsFolderTabs_Factory(t) {
      return new (t || TdsFolderTabs)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsFolderTabs,
      selectors: [["tds-folder-tabs"]],
      inputs: {
        defaultSelectedIndex: "defaultSelectedIndex",
        modeVariant: "modeVariant",
        selectedIndex: "selectedIndex"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsFolderTabs_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsFolderTabs = __decorate([ProxyCmp({
  inputs: ['defaultSelectedIndex', 'modeVariant', 'selectedIndex'],
  methods: ['selectTab']
})], TdsFolderTabs);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsFolderTabs, [{
    type: Component,
    args: [{
      selector: 'tds-folder-tabs',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['defaultSelectedIndex', 'modeVariant', 'selectedIndex']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsFooter = class TdsFooter {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsFooter_Factory(t) {
      return new (t || TdsFooter)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsFooter,
      selectors: [["tds-footer"]],
      inputs: {
        modeVariant: "modeVariant"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsFooter_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsFooter = __decorate([ProxyCmp({
  inputs: ['modeVariant']
})], TdsFooter);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsFooter, [{
    type: Component,
    args: [{
      selector: 'tds-footer',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['modeVariant']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsFooterGroup = class TdsFooterGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsFooterGroup_Factory(t) {
      return new (t || TdsFooterGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsFooterGroup,
      selectors: [["tds-footer-group"]],
      inputs: {
        titleText: "titleText"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsFooterGroup_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsFooterGroup = __decorate([ProxyCmp({
  inputs: ['titleText']
})], TdsFooterGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsFooterGroup, [{
    type: Component,
    args: [{
      selector: 'tds-footer-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['titleText']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsFooterItem = class TdsFooterItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsFooterItem_Factory(t) {
      return new (t || TdsFooterItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsFooterItem,
      selectors: [["tds-footer-item"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsFooterItem_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsFooterItem = __decorate([ProxyCmp({})], TdsFooterItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsFooterItem, [{
    type: Component,
    args: [{
      selector: 'tds-footer-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsHeader = class TdsHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsHeader_Factory(t) {
      return new (t || TdsHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsHeader,
      selectors: [["tds-header"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsHeader_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsHeader = __decorate([ProxyCmp({})], TdsHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsHeader, [{
    type: Component,
    args: [{
      selector: 'tds-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsHeaderBrandSymbol = class TdsHeaderBrandSymbol {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsHeaderBrandSymbol_Factory(t) {
      return new (t || TdsHeaderBrandSymbol)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsHeaderBrandSymbol,
      selectors: [["tds-header-brand-symbol"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsHeaderBrandSymbol_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsHeaderBrandSymbol = __decorate([ProxyCmp({})], TdsHeaderBrandSymbol);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsHeaderBrandSymbol, [{
    type: Component,
    args: [{
      selector: 'tds-header-brand-symbol',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsHeaderCell = class TdsHeaderCell {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsSort']);
  }
  static {
    this.ɵfac = function TdsHeaderCell_Factory(t) {
      return new (t || TdsHeaderCell)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsHeaderCell,
      selectors: [["tds-header-cell"]],
      inputs: {
        cellKey: "cellKey",
        cellValue: "cellValue",
        customWidth: "customWidth",
        sortable: "sortable",
        textAlign: "textAlign"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsHeaderCell_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsHeaderCell = __decorate([ProxyCmp({
  inputs: ['cellKey', 'cellValue', 'customWidth', 'sortable', 'textAlign']
})], TdsHeaderCell);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsHeaderCell, [{
    type: Component,
    args: [{
      selector: 'tds-header-cell',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['cellKey', 'cellValue', 'customWidth', 'sortable', 'textAlign']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsHeaderDropdown = class TdsHeaderDropdown {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsHeaderDropdown_Factory(t) {
      return new (t || TdsHeaderDropdown)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsHeaderDropdown,
      selectors: [["tds-header-dropdown"]],
      inputs: {
        label: "label",
        noDropdownIcon: "noDropdownIcon",
        selected: "selected"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsHeaderDropdown_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsHeaderDropdown = __decorate([ProxyCmp({
  inputs: ['label', 'noDropdownIcon', 'selected']
})], TdsHeaderDropdown);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsHeaderDropdown, [{
    type: Component,
    args: [{
      selector: 'tds-header-dropdown',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['label', 'noDropdownIcon', 'selected']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsHeaderDropdownList = class TdsHeaderDropdownList {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsHeaderDropdownList_Factory(t) {
      return new (t || TdsHeaderDropdownList)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsHeaderDropdownList,
      selectors: [["tds-header-dropdown-list"]],
      inputs: {
        size: "size"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsHeaderDropdownList_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsHeaderDropdownList = __decorate([ProxyCmp({
  inputs: ['size']
})], TdsHeaderDropdownList);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsHeaderDropdownList, [{
    type: Component,
    args: [{
      selector: 'tds-header-dropdown-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['size']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsHeaderDropdownListItem = class TdsHeaderDropdownListItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsHeaderDropdownListItem_Factory(t) {
      return new (t || TdsHeaderDropdownListItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsHeaderDropdownListItem,
      selectors: [["tds-header-dropdown-list-item"]],
      inputs: {
        selected: "selected",
        size: "size"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsHeaderDropdownListItem_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsHeaderDropdownListItem = __decorate([ProxyCmp({
  inputs: ['selected', 'size']
})], TdsHeaderDropdownListItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsHeaderDropdownListItem, [{
    type: Component,
    args: [{
      selector: 'tds-header-dropdown-list-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['selected', 'size']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsHeaderDropdownListUser = class TdsHeaderDropdownListUser {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsHeaderDropdownListUser_Factory(t) {
      return new (t || TdsHeaderDropdownListUser)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsHeaderDropdownListUser,
      selectors: [["tds-header-dropdown-list-user"]],
      inputs: {
        header: "header",
        imgAlt: "imgAlt",
        imgUrl: "imgUrl",
        subheader: "subheader"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsHeaderDropdownListUser_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsHeaderDropdownListUser = __decorate([ProxyCmp({
  inputs: ['header', 'imgAlt', 'imgUrl', 'subheader']
})], TdsHeaderDropdownListUser);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsHeaderDropdownListUser, [{
    type: Component,
    args: [{
      selector: 'tds-header-dropdown-list-user',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['header', 'imgAlt', 'imgUrl', 'subheader']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsHeaderHamburger = class TdsHeaderHamburger {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsHeaderHamburger_Factory(t) {
      return new (t || TdsHeaderHamburger)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsHeaderHamburger,
      selectors: [["tds-header-hamburger"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsHeaderHamburger_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsHeaderHamburger = __decorate([ProxyCmp({})], TdsHeaderHamburger);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsHeaderHamburger, [{
    type: Component,
    args: [{
      selector: 'tds-header-hamburger',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsHeaderItem = class TdsHeaderItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsHeaderItem_Factory(t) {
      return new (t || TdsHeaderItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsHeaderItem,
      selectors: [["tds-header-item"]],
      inputs: {
        active: "active",
        selected: "selected"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsHeaderItem_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsHeaderItem = __decorate([ProxyCmp({
  inputs: ['active', 'selected']
})], TdsHeaderItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsHeaderItem, [{
    type: Component,
    args: [{
      selector: 'tds-header-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['active', 'selected']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsHeaderLauncher = class TdsHeaderLauncher {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsHeaderLauncher_Factory(t) {
      return new (t || TdsHeaderLauncher)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsHeaderLauncher,
      selectors: [["tds-header-launcher"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsHeaderLauncher_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsHeaderLauncher = __decorate([ProxyCmp({})], TdsHeaderLauncher);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsHeaderLauncher, [{
    type: Component,
    args: [{
      selector: 'tds-header-launcher',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsHeaderLauncherButton = class TdsHeaderLauncherButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsHeaderLauncherButton_Factory(t) {
      return new (t || TdsHeaderLauncherButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsHeaderLauncherButton,
      selectors: [["tds-header-launcher-button"]],
      inputs: {
        active: "active"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsHeaderLauncherButton_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsHeaderLauncherButton = __decorate([ProxyCmp({
  inputs: ['active']
})], TdsHeaderLauncherButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsHeaderLauncherButton, [{
    type: Component,
    args: [{
      selector: 'tds-header-launcher-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['active']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsHeaderLauncherGrid = class TdsHeaderLauncherGrid {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsHeaderLauncherGrid_Factory(t) {
      return new (t || TdsHeaderLauncherGrid)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsHeaderLauncherGrid,
      selectors: [["tds-header-launcher-grid"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsHeaderLauncherGrid_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsHeaderLauncherGrid = __decorate([ProxyCmp({})], TdsHeaderLauncherGrid);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsHeaderLauncherGrid, [{
    type: Component,
    args: [{
      selector: 'tds-header-launcher-grid',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsHeaderLauncherGridItem = class TdsHeaderLauncherGridItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsHeaderLauncherGridItem_Factory(t) {
      return new (t || TdsHeaderLauncherGridItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsHeaderLauncherGridItem,
      selectors: [["tds-header-launcher-grid-item"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsHeaderLauncherGridItem_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsHeaderLauncherGridItem = __decorate([ProxyCmp({})], TdsHeaderLauncherGridItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsHeaderLauncherGridItem, [{
    type: Component,
    args: [{
      selector: 'tds-header-launcher-grid-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsHeaderLauncherGridTitle = class TdsHeaderLauncherGridTitle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsHeaderLauncherGridTitle_Factory(t) {
      return new (t || TdsHeaderLauncherGridTitle)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsHeaderLauncherGridTitle,
      selectors: [["tds-header-launcher-grid-title"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsHeaderLauncherGridTitle_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsHeaderLauncherGridTitle = __decorate([ProxyCmp({})], TdsHeaderLauncherGridTitle);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsHeaderLauncherGridTitle, [{
    type: Component,
    args: [{
      selector: 'tds-header-launcher-grid-title',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsHeaderLauncherList = class TdsHeaderLauncherList {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsHeaderLauncherList_Factory(t) {
      return new (t || TdsHeaderLauncherList)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsHeaderLauncherList,
      selectors: [["tds-header-launcher-list"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsHeaderLauncherList_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsHeaderLauncherList = __decorate([ProxyCmp({})], TdsHeaderLauncherList);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsHeaderLauncherList, [{
    type: Component,
    args: [{
      selector: 'tds-header-launcher-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsHeaderLauncherListItem = class TdsHeaderLauncherListItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsHeaderLauncherListItem_Factory(t) {
      return new (t || TdsHeaderLauncherListItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsHeaderLauncherListItem,
      selectors: [["tds-header-launcher-list-item"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsHeaderLauncherListItem_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsHeaderLauncherListItem = __decorate([ProxyCmp({})], TdsHeaderLauncherListItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsHeaderLauncherListItem, [{
    type: Component,
    args: [{
      selector: 'tds-header-launcher-list-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsHeaderLauncherListTitle = class TdsHeaderLauncherListTitle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsHeaderLauncherListTitle_Factory(t) {
      return new (t || TdsHeaderLauncherListTitle)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsHeaderLauncherListTitle,
      selectors: [["tds-header-launcher-list-title"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsHeaderLauncherListTitle_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsHeaderLauncherListTitle = __decorate([ProxyCmp({})], TdsHeaderLauncherListTitle);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsHeaderLauncherListTitle, [{
    type: Component,
    args: [{
      selector: 'tds-header-launcher-list-title',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsHeaderTitle = class TdsHeaderTitle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsHeaderTitle_Factory(t) {
      return new (t || TdsHeaderTitle)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsHeaderTitle,
      selectors: [["tds-header-title"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsHeaderTitle_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsHeaderTitle = __decorate([ProxyCmp({})], TdsHeaderTitle);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsHeaderTitle, [{
    type: Component,
    args: [{
      selector: 'tds-header-title',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsIcon = class TdsIcon {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsIcon_Factory(t) {
      return new (t || TdsIcon)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsIcon,
      selectors: [["tds-icon"]],
      inputs: {
        name: "name",
        size: "size",
        svgDescription: "svgDescription",
        svgTitle: "svgTitle"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsIcon_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsIcon = __decorate([ProxyCmp({
  inputs: ['name', 'size', 'svgDescription', 'svgTitle']
})], TdsIcon);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsIcon, [{
    type: Component,
    args: [{
      selector: 'tds-icon',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['name', 'size', 'svgDescription', 'svgTitle']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsInlineTab = class TdsInlineTab {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsInlineTab_Factory(t) {
      return new (t || TdsInlineTab)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsInlineTab,
      selectors: [["tds-inline-tab"]],
      inputs: {
        disabled: "disabled"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsInlineTab_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsInlineTab = __decorate([ProxyCmp({
  inputs: ['disabled']
})], TdsInlineTab);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsInlineTab, [{
    type: Component,
    args: [{
      selector: 'tds-inline-tab',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsInlineTabs = class TdsInlineTabs {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsChange']);
  }
  static {
    this.ɵfac = function TdsInlineTabs_Factory(t) {
      return new (t || TdsInlineTabs)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsInlineTabs,
      selectors: [["tds-inline-tabs"]],
      inputs: {
        defaultSelectedIndex: "defaultSelectedIndex",
        modeVariant: "modeVariant",
        selectedIndex: "selectedIndex"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsInlineTabs_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsInlineTabs = __decorate([ProxyCmp({
  inputs: ['defaultSelectedIndex', 'modeVariant', 'selectedIndex'],
  methods: ['selectTab']
})], TdsInlineTabs);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsInlineTabs, [{
    type: Component,
    args: [{
      selector: 'tds-inline-tabs',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['defaultSelectedIndex', 'modeVariant', 'selectedIndex']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsLink = class TdsLink {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsLink_Factory(t) {
      return new (t || TdsLink)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsLink,
      selectors: [["tds-link"]],
      inputs: {
        disabled: "disabled",
        underline: "underline"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsLink_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsLink = __decorate([ProxyCmp({
  inputs: ['disabled', 'underline']
})], TdsLink);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsLink, [{
    type: Component,
    args: [{
      selector: 'tds-link',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'underline']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsMessage = class TdsMessage {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsMessage_Factory(t) {
      return new (t || TdsMessage)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsMessage,
      selectors: [["tds-message"]],
      inputs: {
        header: "header",
        minimal: "minimal",
        modeVariant: "modeVariant",
        noIcon: "noIcon",
        variant: "variant"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsMessage_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsMessage = __decorate([ProxyCmp({
  inputs: ['header', 'minimal', 'modeVariant', 'noIcon', 'variant']
})], TdsMessage);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsMessage, [{
    type: Component,
    args: [{
      selector: 'tds-message',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['header', 'minimal', 'modeVariant', 'noIcon', 'variant']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsModal = class TdsModal {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsClose']);
  }
  static {
    this.ɵfac = function TdsModal_Factory(t) {
      return new (t || TdsModal)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsModal,
      selectors: [["tds-modal"]],
      inputs: {
        actionsPosition: "actionsPosition",
        closable: "closable",
        header: "header",
        prevent: "prevent",
        referenceEl: "referenceEl",
        selector: "selector",
        show: "show",
        size: "size"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsModal_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsModal = __decorate([ProxyCmp({
  inputs: ['actionsPosition', 'closable', 'header', 'prevent', 'referenceEl', 'selector', 'show', 'size'],
  methods: ['showModal', 'closeModal', 'initializeModal', 'cleanupModal']
})], TdsModal);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsModal, [{
    type: Component,
    args: [{
      selector: 'tds-modal',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['actionsPosition', 'closable', 'header', 'prevent', 'referenceEl', 'selector', 'show', 'size']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsNavigationTab = class TdsNavigationTab {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsNavigationTab_Factory(t) {
      return new (t || TdsNavigationTab)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsNavigationTab,
      selectors: [["tds-navigation-tab"]],
      inputs: {
        disabled: "disabled"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsNavigationTab_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsNavigationTab = __decorate([ProxyCmp({
  inputs: ['disabled']
})], TdsNavigationTab);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsNavigationTab, [{
    type: Component,
    args: [{
      selector: 'tds-navigation-tab',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsNavigationTabs = class TdsNavigationTabs {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsChange']);
  }
  static {
    this.ɵfac = function TdsNavigationTabs_Factory(t) {
      return new (t || TdsNavigationTabs)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsNavigationTabs,
      selectors: [["tds-navigation-tabs"]],
      inputs: {
        defaultSelectedIndex: "defaultSelectedIndex",
        modeVariant: "modeVariant",
        selectedIndex: "selectedIndex"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsNavigationTabs_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsNavigationTabs = __decorate([ProxyCmp({
  inputs: ['defaultSelectedIndex', 'modeVariant', 'selectedIndex'],
  methods: ['selectTab']
})], TdsNavigationTabs);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsNavigationTabs, [{
    type: Component,
    args: [{
      selector: 'tds-navigation-tabs',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['defaultSelectedIndex', 'modeVariant', 'selectedIndex']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsPopoverCanvas = class TdsPopoverCanvas {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsPopoverCanvas_Factory(t) {
      return new (t || TdsPopoverCanvas)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsPopoverCanvas,
      selectors: [["tds-popover-canvas"]],
      inputs: {
        modifiers: "modifiers",
        offsetDistance: "offsetDistance",
        offsetSkidding: "offsetSkidding",
        placement: "placement",
        referenceEl: "referenceEl",
        selector: "selector",
        show: "show"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsPopoverCanvas_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsPopoverCanvas = __decorate([ProxyCmp({
  inputs: ['modifiers', 'offsetDistance', 'offsetSkidding', 'placement', 'referenceEl', 'selector', 'show']
})], TdsPopoverCanvas);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsPopoverCanvas, [{
    type: Component,
    args: [{
      selector: 'tds-popover-canvas',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['modifiers', 'offsetDistance', 'offsetSkidding', 'placement', 'referenceEl', 'selector', 'show']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsPopoverCore = class TdsPopoverCore {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsPopoverCore_Factory(t) {
      return new (t || TdsPopoverCore)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsPopoverCore,
      selectors: [["tds-popover-core"]],
      inputs: {
        autoHide: "autoHide",
        modifiers: "modifiers",
        offsetDistance: "offsetDistance",
        offsetSkidding: "offsetSkidding",
        placement: "placement",
        referenceEl: "referenceEl",
        selector: "selector",
        show: "show",
        trigger: "trigger"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsPopoverCore_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsPopoverCore = __decorate([ProxyCmp({
  inputs: ['autoHide', 'modifiers', 'offsetDistance', 'offsetSkidding', 'placement', 'referenceEl', 'selector', 'show', 'trigger']
})], TdsPopoverCore);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsPopoverCore, [{
    type: Component,
    args: [{
      selector: 'tds-popover-core',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autoHide', 'modifiers', 'offsetDistance', 'offsetSkidding', 'placement', 'referenceEl', 'selector', 'show', 'trigger']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsPopoverMenu = class TdsPopoverMenu {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsPopoverMenu_Factory(t) {
      return new (t || TdsPopoverMenu)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsPopoverMenu,
      selectors: [["tds-popover-menu"]],
      inputs: {
        fluidWidth: "fluidWidth",
        offsetDistance: "offsetDistance",
        offsetSkidding: "offsetSkidding",
        placement: "placement",
        referenceEl: "referenceEl",
        selector: "selector",
        show: "show"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsPopoverMenu_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsPopoverMenu = __decorate([ProxyCmp({
  inputs: ['fluidWidth', 'offsetDistance', 'offsetSkidding', 'placement', 'referenceEl', 'selector', 'show']
})], TdsPopoverMenu);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsPopoverMenu, [{
    type: Component,
    args: [{
      selector: 'tds-popover-menu',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['fluidWidth', 'offsetDistance', 'offsetSkidding', 'placement', 'referenceEl', 'selector', 'show']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsPopoverMenuItem = class TdsPopoverMenuItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsPopoverMenuItem_Factory(t) {
      return new (t || TdsPopoverMenuItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsPopoverMenuItem,
      selectors: [["tds-popover-menu-item"]],
      inputs: {
        disabled: "disabled"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsPopoverMenuItem_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsPopoverMenuItem = __decorate([ProxyCmp({
  inputs: ['disabled']
})], TdsPopoverMenuItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsPopoverMenuItem, [{
    type: Component,
    args: [{
      selector: 'tds-popover-menu-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsRadioButton = class TdsRadioButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsChange']);
  }
  static {
    this.ɵfac = function TdsRadioButton_Factory(t) {
      return new (t || TdsRadioButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsRadioButton,
      selectors: [["tds-radio-button"]],
      inputs: {
        checked: "checked",
        disabled: "disabled",
        name: "name",
        radioId: "radioId",
        required: "required",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsRadioButton_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsRadioButton = __decorate([ProxyCmp({
  inputs: ['checked', 'disabled', 'name', 'radioId', 'required', 'value']
})], TdsRadioButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsRadioButton, [{
    type: Component,
    args: [{
      selector: 'tds-radio-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checked', 'disabled', 'name', 'radioId', 'required', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsSideMenu = class TdsSideMenu {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsCollapse']);
  }
  static {
    this.ɵfac = function TdsSideMenu_Factory(t) {
      return new (t || TdsSideMenu)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsSideMenu,
      selectors: [["tds-side-menu"]],
      inputs: {
        collapsed: "collapsed",
        open: "open",
        persistent: "persistent"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsSideMenu_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsSideMenu = __decorate([ProxyCmp({
  inputs: ['collapsed', 'open', 'persistent']
})], TdsSideMenu);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsSideMenu, [{
    type: Component,
    args: [{
      selector: 'tds-side-menu',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['collapsed', 'open', 'persistent']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsSideMenuCloseButton = class TdsSideMenuCloseButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsSideMenuCloseButton_Factory(t) {
      return new (t || TdsSideMenuCloseButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsSideMenuCloseButton,
      selectors: [["tds-side-menu-close-button"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsSideMenuCloseButton_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsSideMenuCloseButton = __decorate([ProxyCmp({})], TdsSideMenuCloseButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsSideMenuCloseButton, [{
    type: Component,
    args: [{
      selector: 'tds-side-menu-close-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsSideMenuCollapseButton = class TdsSideMenuCollapseButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsCollapse']);
  }
  static {
    this.ɵfac = function TdsSideMenuCollapseButton_Factory(t) {
      return new (t || TdsSideMenuCollapseButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsSideMenuCollapseButton,
      selectors: [["tds-side-menu-collapse-button"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsSideMenuCollapseButton_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsSideMenuCollapseButton = __decorate([ProxyCmp({})], TdsSideMenuCollapseButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsSideMenuCollapseButton, [{
    type: Component,
    args: [{
      selector: 'tds-side-menu-collapse-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsSideMenuDropdown = class TdsSideMenuDropdown {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsSideMenuDropdown_Factory(t) {
      return new (t || TdsSideMenuDropdown)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsSideMenuDropdown,
      selectors: [["tds-side-menu-dropdown"]],
      inputs: {
        buttonLabel: "buttonLabel",
        defaultOpen: "defaultOpen",
        open: "open",
        selected: "selected"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsSideMenuDropdown_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsSideMenuDropdown = __decorate([ProxyCmp({
  inputs: ['buttonLabel', 'defaultOpen', 'open', 'selected']
})], TdsSideMenuDropdown);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsSideMenuDropdown, [{
    type: Component,
    args: [{
      selector: 'tds-side-menu-dropdown',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['buttonLabel', 'defaultOpen', 'open', 'selected']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsSideMenuDropdownList = class TdsSideMenuDropdownList {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsSideMenuDropdownList_Factory(t) {
      return new (t || TdsSideMenuDropdownList)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsSideMenuDropdownList,
      selectors: [["tds-side-menu-dropdown-list"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsSideMenuDropdownList_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsSideMenuDropdownList = __decorate([ProxyCmp({})], TdsSideMenuDropdownList);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsSideMenuDropdownList, [{
    type: Component,
    args: [{
      selector: 'tds-side-menu-dropdown-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsSideMenuDropdownListItem = class TdsSideMenuDropdownListItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsSideMenuDropdownListItem_Factory(t) {
      return new (t || TdsSideMenuDropdownListItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsSideMenuDropdownListItem,
      selectors: [["tds-side-menu-dropdown-list-item"]],
      inputs: {
        selected: "selected"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsSideMenuDropdownListItem_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsSideMenuDropdownListItem = __decorate([ProxyCmp({
  inputs: ['selected']
})], TdsSideMenuDropdownListItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsSideMenuDropdownListItem, [{
    type: Component,
    args: [{
      selector: 'tds-side-menu-dropdown-list-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['selected']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsSideMenuItem = class TdsSideMenuItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsSideMenuItem_Factory(t) {
      return new (t || TdsSideMenuItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsSideMenuItem,
      selectors: [["tds-side-menu-item"]],
      inputs: {
        active: "active",
        selected: "selected"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsSideMenuItem_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsSideMenuItem = __decorate([ProxyCmp({
  inputs: ['active', 'selected']
})], TdsSideMenuItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsSideMenuItem, [{
    type: Component,
    args: [{
      selector: 'tds-side-menu-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['active', 'selected']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsSideMenuOverlay = class TdsSideMenuOverlay {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsSideMenuOverlay_Factory(t) {
      return new (t || TdsSideMenuOverlay)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsSideMenuOverlay,
      selectors: [["tds-side-menu-overlay"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsSideMenuOverlay_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsSideMenuOverlay = __decorate([ProxyCmp({})], TdsSideMenuOverlay);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsSideMenuOverlay, [{
    type: Component,
    args: [{
      selector: 'tds-side-menu-overlay',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsSideMenuUser = class TdsSideMenuUser {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsSideMenuUser_Factory(t) {
      return new (t || TdsSideMenuUser)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsSideMenuUser,
      selectors: [["tds-side-menu-user"]],
      inputs: {
        heading: "heading",
        imgAlt: "imgAlt",
        imgSrc: "imgSrc",
        subheading: "subheading"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsSideMenuUser_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsSideMenuUser = __decorate([ProxyCmp({
  inputs: ['heading', 'imgAlt', 'imgSrc', 'subheading']
})], TdsSideMenuUser);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsSideMenuUser, [{
    type: Component,
    args: [{
      selector: 'tds-side-menu-user',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['heading', 'imgAlt', 'imgSrc', 'subheading']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsSideMenuUserImage = class TdsSideMenuUserImage {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsSideMenuUserImage_Factory(t) {
      return new (t || TdsSideMenuUserImage)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsSideMenuUserImage,
      selectors: [["tds-side-menu-user-image"]],
      inputs: {
        alt: "alt",
        src: "src"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsSideMenuUserImage_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsSideMenuUserImage = __decorate([ProxyCmp({
  inputs: ['alt', 'src']
})], TdsSideMenuUserImage);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsSideMenuUserImage, [{
    type: Component,
    args: [{
      selector: 'tds-side-menu-user-image',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['alt', 'src']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsSideMenuUserLabel = class TdsSideMenuUserLabel {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsSideMenuUserLabel_Factory(t) {
      return new (t || TdsSideMenuUserLabel)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsSideMenuUserLabel,
      selectors: [["tds-side-menu-user-label"]],
      inputs: {
        heading: "heading",
        subheading: "subheading"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsSideMenuUserLabel_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsSideMenuUserLabel = __decorate([ProxyCmp({
  inputs: ['heading', 'subheading']
})], TdsSideMenuUserLabel);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsSideMenuUserLabel, [{
    type: Component,
    args: [{
      selector: 'tds-side-menu-user-label',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['heading', 'subheading']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsSlider = class TdsSlider {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsChange', 'tdsInput']);
  }
  static {
    this.ɵfac = function TdsSlider_Factory(t) {
      return new (t || TdsSlider)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsSlider,
      selectors: [["tds-slider"]],
      inputs: {
        controls: "controls",
        disabled: "disabled",
        input: "input",
        label: "label",
        max: "max",
        min: "min",
        name: "name",
        readOnly: "readOnly",
        showTickNumbers: "showTickNumbers",
        sliderId: "sliderId",
        snap: "snap",
        step: "step",
        thumbSize: "thumbSize",
        ticks: "ticks",
        tooltip: "tooltip",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsSlider_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsSlider = __decorate([ProxyCmp({
  inputs: ['controls', 'disabled', 'input', 'label', 'max', 'min', 'name', 'readOnly', 'showTickNumbers', 'sliderId', 'snap', 'step', 'thumbSize', 'ticks', 'tooltip', 'value'],
  methods: ['reset']
})], TdsSlider);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsSlider, [{
    type: Component,
    args: [{
      selector: 'tds-slider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['controls', 'disabled', 'input', 'label', 'max', 'min', 'name', 'readOnly', 'showTickNumbers', 'sliderId', 'snap', 'step', 'thumbSize', 'ticks', 'tooltip', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsSpinner = class TdsSpinner {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsSpinner_Factory(t) {
      return new (t || TdsSpinner)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsSpinner,
      selectors: [["tds-spinner"]],
      inputs: {
        size: "size",
        variant: "variant"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsSpinner_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsSpinner = __decorate([ProxyCmp({
  inputs: ['size', 'variant']
})], TdsSpinner);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsSpinner, [{
    type: Component,
    args: [{
      selector: 'tds-spinner',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['size', 'variant']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsStep = class TdsStep {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsStep_Factory(t) {
      return new (t || TdsStep)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsStep,
      selectors: [["tds-step"]],
      inputs: {
        index: "index",
        state: "state"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsStep_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsStep = __decorate([ProxyCmp({
  inputs: ['index', 'state']
})], TdsStep);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsStep, [{
    type: Component,
    args: [{
      selector: 'tds-step',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['index', 'state']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsStepper = class TdsStepper {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsStepper_Factory(t) {
      return new (t || TdsStepper)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsStepper,
      selectors: [["tds-stepper"]],
      inputs: {
        hideLabels: "hideLabels",
        labelPosition: "labelPosition",
        orientation: "orientation",
        size: "size",
        stepperId: "stepperId"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsStepper_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsStepper = __decorate([ProxyCmp({
  inputs: ['hideLabels', 'labelPosition', 'orientation', 'size', 'stepperId']
})], TdsStepper);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsStepper, [{
    type: Component,
    args: [{
      selector: 'tds-stepper',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['hideLabels', 'labelPosition', 'orientation', 'size', 'stepperId']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsTable = class TdsTable {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsTable_Factory(t) {
      return new (t || TdsTable)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsTable,
      selectors: [["tds-table"]],
      inputs: {
        compactDesign: "compactDesign",
        expandableRows: "expandableRows",
        modeVariant: "modeVariant",
        multiselect: "multiselect",
        noMinWidth: "noMinWidth",
        responsive: "responsive",
        tableId: "tableId",
        verticalDividers: "verticalDividers"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsTable_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsTable = __decorate([ProxyCmp({
  inputs: ['compactDesign', 'expandableRows', 'modeVariant', 'multiselect', 'noMinWidth', 'responsive', 'tableId', 'verticalDividers'],
  methods: ['getSelectedRows']
})], TdsTable);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsTable, [{
    type: Component,
    args: [{
      selector: 'tds-table',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['compactDesign', 'expandableRows', 'modeVariant', 'multiselect', 'noMinWidth', 'responsive', 'tableId', 'verticalDividers']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsTableBody = class TdsTableBody {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsTableBody_Factory(t) {
      return new (t || TdsTableBody)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsTableBody,
      selectors: [["tds-table-body"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsTableBody_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsTableBody = __decorate([ProxyCmp({})], TdsTableBody);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsTableBody, [{
    type: Component,
    args: [{
      selector: 'tds-table-body',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsTableBodyRow = class TdsTableBodyRow {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsSelect']);
  }
  static {
    this.ɵfac = function TdsTableBodyRow_Factory(t) {
      return new (t || TdsTableBodyRow)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsTableBodyRow,
      selectors: [["tds-table-body-row"]],
      inputs: {
        disabled: "disabled",
        selected: "selected"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsTableBodyRow_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsTableBodyRow = __decorate([ProxyCmp({
  inputs: ['disabled', 'selected']
})], TdsTableBodyRow);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsTableBodyRow, [{
    type: Component,
    args: [{
      selector: 'tds-table-body-row',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'selected']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsTableBodyRowExpandable = class TdsTableBodyRowExpandable {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsChange']);
  }
  static {
    this.ɵfac = function TdsTableBodyRowExpandable_Factory(t) {
      return new (t || TdsTableBodyRowExpandable)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsTableBodyRowExpandable,
      selectors: [["tds-table-body-row-expandable"]],
      inputs: {
        colSpan: "colSpan",
        expanded: "expanded",
        rowId: "rowId"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsTableBodyRowExpandable_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsTableBodyRowExpandable = __decorate([ProxyCmp({
  inputs: ['colSpan', 'expanded', 'rowId'],
  methods: ['expand', 'collapse']
})], TdsTableBodyRowExpandable);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsTableBodyRowExpandable, [{
    type: Component,
    args: [{
      selector: 'tds-table-body-row-expandable',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['colSpan', 'expanded', 'rowId']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsTableFooter = class TdsTableFooter {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsPagination']);
  }
  static {
    this.ɵfac = function TdsTableFooter_Factory(t) {
      return new (t || TdsTableFooter)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsTableFooter,
      selectors: [["tds-table-footer"]],
      inputs: {
        cols: "cols",
        pages: "pages",
        pagination: "pagination",
        paginationValue: "paginationValue"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsTableFooter_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsTableFooter = __decorate([ProxyCmp({
  inputs: ['cols', 'pages', 'pagination', 'paginationValue']
})], TdsTableFooter);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsTableFooter, [{
    type: Component,
    args: [{
      selector: 'tds-table-footer',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['cols', 'pages', 'pagination', 'paginationValue']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsTableHeader = class TdsTableHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsSelectAll']);
  }
  static {
    this.ɵfac = function TdsTableHeader_Factory(t) {
      return new (t || TdsTableHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsTableHeader,
      selectors: [["tds-table-header"]],
      inputs: {
        allSelected: "allSelected",
        disabled: "disabled",
        indeterminate: "indeterminate",
        selected: "selected"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsTableHeader_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsTableHeader = __decorate([ProxyCmp({
  inputs: ['allSelected', 'disabled', 'indeterminate', 'selected']
})], TdsTableHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsTableHeader, [{
    type: Component,
    args: [{
      selector: 'tds-table-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['allSelected', 'disabled', 'indeterminate', 'selected']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsTableToolbar = class TdsTableToolbar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsFilter']);
  }
  static {
    this.ɵfac = function TdsTableToolbar_Factory(t) {
      return new (t || TdsTableToolbar)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsTableToolbar,
      selectors: [["tds-table-toolbar"]],
      inputs: {
        filter: "filter",
        tableTitle: "tableTitle"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsTableToolbar_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsTableToolbar = __decorate([ProxyCmp({
  inputs: ['filter', 'tableTitle']
})], TdsTableToolbar);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsTableToolbar, [{
    type: Component,
    args: [{
      selector: 'tds-table-toolbar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['filter', 'tableTitle']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsTextField = class TdsTextField {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsChange', 'tdsInput', 'tdsFocus', 'tdsBlur']);
  }
  static {
    this.ɵfac = function TdsTextField_Factory(t) {
      return new (t || TdsTextField)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsTextField,
      selectors: [["tds-text-field"]],
      inputs: {
        autofocus: "autofocus",
        disabled: "disabled",
        helper: "helper",
        label: "label",
        labelPosition: "labelPosition",
        max: "max",
        maxLength: "maxLength",
        min: "min",
        modeVariant: "modeVariant",
        name: "name",
        noMinWidth: "noMinWidth",
        placeholder: "placeholder",
        readOnly: "readOnly",
        size: "size",
        state: "state",
        type: "type",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsTextField_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsTextField = __decorate([ProxyCmp({
  inputs: ['autofocus', 'disabled', 'helper', 'label', 'labelPosition', 'max', 'maxLength', 'min', 'modeVariant', 'name', 'noMinWidth', 'placeholder', 'readOnly', 'size', 'state', 'type', 'value']
})], TdsTextField);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsTextField, [{
    type: Component,
    args: [{
      selector: 'tds-text-field',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autofocus', 'disabled', 'helper', 'label', 'labelPosition', 'max', 'maxLength', 'min', 'modeVariant', 'name', 'noMinWidth', 'placeholder', 'readOnly', 'size', 'state', 'type', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsTextarea = class TdsTextarea {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsChange', 'tdsBlur', 'tdsInput', 'tdsFocus']);
  }
  static {
    this.ɵfac = function TdsTextarea_Factory(t) {
      return new (t || TdsTextarea)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsTextarea,
      selectors: [["tds-textarea"]],
      inputs: {
        autofocus: "autofocus",
        cols: "cols",
        disabled: "disabled",
        helper: "helper",
        label: "label",
        labelPosition: "labelPosition",
        maxLength: "maxLength",
        modeVariant: "modeVariant",
        name: "name",
        noMinWidth: "noMinWidth",
        placeholder: "placeholder",
        readOnly: "readOnly",
        rows: "rows",
        state: "state",
        value: "value"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsTextarea_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsTextarea = __decorate([ProxyCmp({
  inputs: ['autofocus', 'cols', 'disabled', 'helper', 'label', 'labelPosition', 'maxLength', 'modeVariant', 'name', 'noMinWidth', 'placeholder', 'readOnly', 'rows', 'state', 'value']
})], TdsTextarea);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsTextarea, [{
    type: Component,
    args: [{
      selector: 'tds-textarea',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autofocus', 'cols', 'disabled', 'helper', 'label', 'labelPosition', 'maxLength', 'modeVariant', 'name', 'noMinWidth', 'placeholder', 'readOnly', 'rows', 'state', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsToast = class TdsToast {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsClose']);
  }
  static {
    this.ɵfac = function TdsToast_Factory(t) {
      return new (t || TdsToast)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsToast,
      selectors: [["tds-toast"]],
      inputs: {
        closable: "closable",
        header: "header",
        hidden: "hidden",
        subheader: "subheader",
        toastId: "toastId",
        toastRole: "toastRole",
        variant: "variant"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsToast_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsToast = __decorate([ProxyCmp({
  inputs: ['closable', 'header', 'hidden', 'subheader', 'toastId', 'toastRole', 'variant'],
  methods: ['hideToast', 'showToast']
})], TdsToast);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsToast, [{
    type: Component,
    args: [{
      selector: 'tds-toast',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['closable', 'header', 'hidden', 'subheader', 'toastId', 'toastRole', 'variant']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsToggle = class TdsToggle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['tdsToggle']);
  }
  static {
    this.ɵfac = function TdsToggle_Factory(t) {
      return new (t || TdsToggle)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsToggle,
      selectors: [["tds-toggle"]],
      inputs: {
        checked: "checked",
        disabled: "disabled",
        headline: "headline",
        name: "name",
        required: "required",
        size: "size",
        toggleId: "toggleId"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsToggle_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsToggle = __decorate([ProxyCmp({
  inputs: ['checked', 'disabled', 'headline', 'name', 'required', 'size', 'toggleId'],
  methods: ['toggle']
})], TdsToggle);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsToggle, [{
    type: Component,
    args: [{
      selector: 'tds-toggle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checked', 'disabled', 'headline', 'name', 'required', 'size', 'toggleId']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let TdsTooltip = class TdsTooltip {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  static {
    this.ɵfac = function TdsTooltip_Factory(t) {
      return new (t || TdsTooltip)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TdsTooltip,
      selectors: [["tds-tooltip"]],
      inputs: {
        mouseOverTooltip: "mouseOverTooltip",
        offsetDistance: "offsetDistance",
        offsetSkidding: "offsetSkidding",
        placement: "placement",
        referenceEl: "referenceEl",
        selector: "selector",
        show: "show",
        text: "text"
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TdsTooltip_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
};
TdsTooltip = __decorate([ProxyCmp({
  inputs: ['mouseOverTooltip', 'offsetDistance', 'offsetSkidding', 'placement', 'referenceEl', 'selector', 'show', 'text']
})], TdsTooltip);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TdsTooltip, [{
    type: Component,
    args: [{
      selector: 'tds-tooltip',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['mouseOverTooltip', 'offsetDistance', 'offsetSkidding', 'placement', 'referenceEl', 'selector', 'show', 'text']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
const DIRECTIVES = [TdsAccordion, TdsAccordionItem, TdsBadge, TdsBanner, TdsBlock, TdsBodyCell, TdsBreadcrumb, TdsBreadcrumbs, TdsButton, TdsCard, TdsCheckbox, TdsChip, TdsCoreHeaderItem, TdsDatetime, TdsDivider, TdsDropdown, TdsDropdownOption, TdsFolderTab, TdsFolderTabs, TdsFooter, TdsFooterGroup, TdsFooterItem, TdsHeader, TdsHeaderBrandSymbol, TdsHeaderCell, TdsHeaderDropdown, TdsHeaderDropdownList, TdsHeaderDropdownListItem, TdsHeaderDropdownListUser, TdsHeaderHamburger, TdsHeaderItem, TdsHeaderLauncher, TdsHeaderLauncherButton, TdsHeaderLauncherGrid, TdsHeaderLauncherGridItem, TdsHeaderLauncherGridTitle, TdsHeaderLauncherList, TdsHeaderLauncherListItem, TdsHeaderLauncherListTitle, TdsHeaderTitle, TdsIcon, TdsInlineTab, TdsInlineTabs, TdsLink, TdsMessage, TdsModal, TdsNavigationTab, TdsNavigationTabs, TdsPopoverCanvas, TdsPopoverCore, TdsPopoverMenu, TdsPopoverMenuItem, TdsRadioButton, TdsSideMenu, TdsSideMenuCloseButton, TdsSideMenuCollapseButton, TdsSideMenuDropdown, TdsSideMenuDropdownList, TdsSideMenuDropdownListItem, TdsSideMenuItem, TdsSideMenuOverlay, TdsSideMenuUser, TdsSideMenuUserImage, TdsSideMenuUserLabel, TdsSlider, TdsSpinner, TdsStep, TdsStepper, TdsTable, TdsTableBody, TdsTableBodyRow, TdsTableBodyRowExpandable, TdsTableFooter, TdsTableHeader, TdsTableToolbar, TdsTextField, TdsTextarea, TdsToast, TdsToggle, TdsTooltip];
class TegelModule {
  static {
    this.ɵfac = function TegelModule_Factory(t) {
      return new (t || TegelModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TegelModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        provide: APP_INITIALIZER,
        useFactory: () => defineCustomElements,
        multi: true
      }]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TegelModule, [{
    type: NgModule,
    args: [{
      declarations: [...DIRECTIVES],
      exports: [...DIRECTIVES],
      providers: [{
        provide: APP_INITIALIZER,
        useFactory: () => defineCustomElements,
        multi: true
      }]
    }]
  }], null, null);
})();

/*
 * Public API Surface of components
 */

/**
 * Generated bundle index. Do not edit.
 */

export { DIRECTIVES, TdsAccordion, TdsAccordionItem, TdsBadge, TdsBanner, TdsBlock, TdsBodyCell, TdsBreadcrumb, TdsBreadcrumbs, TdsButton, TdsCard, TdsCheckbox, TdsChip, TdsCoreHeaderItem, TdsDatetime, TdsDivider, TdsDropdown, TdsDropdownOption, TdsFolderTab, TdsFolderTabs, TdsFooter, TdsFooterGroup, TdsFooterItem, TdsHeader, TdsHeaderBrandSymbol, TdsHeaderCell, TdsHeaderDropdown, TdsHeaderDropdownList, TdsHeaderDropdownListItem, TdsHeaderDropdownListUser, TdsHeaderHamburger, TdsHeaderItem, TdsHeaderLauncher, TdsHeaderLauncherButton, TdsHeaderLauncherGrid, TdsHeaderLauncherGridItem, TdsHeaderLauncherGridTitle, TdsHeaderLauncherList, TdsHeaderLauncherListItem, TdsHeaderLauncherListTitle, TdsHeaderTitle, TdsIcon, TdsInlineTab, TdsInlineTabs, TdsLink, TdsMessage, TdsModal, TdsNavigationTab, TdsNavigationTabs, TdsPopoverCanvas, TdsPopoverCore, TdsPopoverMenu, TdsPopoverMenuItem, TdsRadioButton, TdsSideMenu, TdsSideMenuCloseButton, TdsSideMenuCollapseButton, TdsSideMenuDropdown, TdsSideMenuDropdownList, TdsSideMenuDropdownListItem, TdsSideMenuItem, TdsSideMenuOverlay, TdsSideMenuUser, TdsSideMenuUserImage, TdsSideMenuUserLabel, TdsSlider, TdsSpinner, TdsStep, TdsStepper, TdsTable, TdsTableBody, TdsTableBodyRow, TdsTableBodyRowExpandable, TdsTableFooter, TdsTableHeader, TdsTableToolbar, TdsTextField, TdsTextarea, TdsToast, TdsToggle, TdsTooltip, TegelModule };
