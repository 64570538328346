import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { retry, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DistributorsService {

  constructor(private http: HttpClient) {}

  getDistributorsCountry() {
    return this.http.get(
      environment.baseUrl + environment.distributorService.distributorCountries
    );
    // return of(distributorsCountries);
  }

  getDistributorDetailsByCountry(countryCode: string) {
    const params = new HttpParams().set('country-code', countryCode);
    return this.http
      .get(environment.baseUrl + environment.distributorService.distributors, {
        params: params,
      })
      .pipe(
        retry(3),
        map((response: any) => response['Distributors'])
      );
    // return of(distributors);
  }

}
