<!-- <div class="grid mainContainer justify-items-center dashboard-img">
  <ng-container>
    <div class="sm:w-full md:w-full lg:w-3/5">
      <div class="tds-headline-04 tds-u-mb2 tds-u-mt2 tds-text-blue-600 leftMargin">
        Welcome to Scania
      </div>
      <div
        class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 leftMargin gap-3"
      >
        @for (item of menuItems; track $index) { @if ($index !=0) {
        <div class="dashboardCards" [routerLink]="item.url">
          <tds-card class="tds-u-p0" image-placement="above-header" [bodyImg]="item.title.toLowerCase() + '.jpg'">
            <div slot="header">
              {{ item.title }}
            </div>
            <tds-icon class="arrowIcon" slot="actions" size="20px" name="arrow_right"></tds-icon>
          </tds-card>
        </div>
        } }
      </div>
    </div>
  </ng-container>
</div> -->

<!-- Pictorial layout -->
<div class="mainContainer grid justify-items-center dashboard-img">
  <div>
    <div class="tds-headline-03 tds-text-white-100 pl-3 mb-2">
      Welcome to Scania
    </div>
    <div
      class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2 right-margin"
    >
      @for (item of menuItems; track $index) { @if ($index !=0) {
      <div class="m-3 bg-white dashboardCards" [routerLink]="item.url">
        <div class="m-3 text-center">
          <a class="{{ item.image }}"></a>
        </div>
        <p class="tds-headline-06 text-wrap text-center break-words">
          {{ item.title }}
        </p>
      </div>
      } }
    </div>
  </div>
</div>
