import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { SubjectService } from '../../services/subject.service';
import { OAuthStorage, OAuthResourceServerErrorHandler, OAuthModuleConfig } from 'angular-oauth2-oidc';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authStorage: OAuthStorage,
    private errorHandler: OAuthResourceServerErrorHandler,
    private subjectService: SubjectService,
    @Optional() private moduleConfig: OAuthModuleConfig
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let url: any;
    if ( req != undefined) {
      url = req.url.toLowerCase();
    }

    if (!this.moduleConfig) return next.handle(req);
    if (!this.moduleConfig.resourceServer) return next.handle(req);
    if (!this.moduleConfig.resourceServer.allowedUrls) return next.handle(req);

    let found = this.moduleConfig.resourceServer.allowedUrls.find(u => url.startsWith(u));
    if (!found) return next.handle(req);

    let sendAccessToken = this.moduleConfig.resourceServer.sendAccessToken;

    if (sendAccessToken) {
      const authToken = this.authStorage.getItem('access_token');

      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`,
        },
        withCredentials: true,
      });
    }


    // Pass the cloned request with the updated header to the next handler
    return next.handle(req).pipe(
      tap((item: any) => {
        this.subjectService.toggleLoaderSubject(true);
        if (item.status)
          setTimeout(() => {
            this.subjectService.toggleLoaderSubject(false);
          }, 700);
      }),
      catchError((err: any) => {
        this.subjectService.toggleLoaderSubject(false);
        if (err instanceof HttpErrorResponse) {
          console.log(err);
          console.log(`error status : ${err.status} ${err.statusText}`);
          switch (err.status) {
            case 401:
              console.log('Unauthorized request:', err);
              // this.router.navigate(["/login"]);

              break;
            case 403:
              console.log('Session Expired');
              sessionStorage.removeItem('token');
              console.log('Forbidden access:', err);

              break;
          }
        } else {
          // Handle non-HTTP errors
          console.error('An error occurred:', err);
        }

        // Re-throw the error to propagate it further
        return throwError(() => err);
      })
    );
  }
}