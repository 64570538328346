import { Component, OnInit } from '@angular/core';
import { AuthService, IdentityClaims } from '../../services/auth.service';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class AuthCallbackComponent implements OnInit {
  constructor(public authService: AuthService, private router: Router) {
    //comment below line to view auth callback result
    this.authService.redirectOnCallback();
  }

  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (!code) {
      if (this.authService.isAuthenticated) {
        this.router.navigateByUrl('/');
      } else {
        this.authService.login();
      }
    }
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }

  get identityClaims(): IdentityClaims | null {
    return this.authService.identityClaims;
  }

  get token(): string | null {
    return this.authService.getAuthorizationToken();
  }
}
