<div class="flex">
    <div class="h-52 w-4/5 grid justify-items-center mt-3">
      <p
        class="font-bold text-3xl inline-block align-text-bottom mt-5"
        id="heading"
      >
       404 Page Not Found
      </p>
    </div>
  </div>

  
  