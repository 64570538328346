import { Component, NO_ERRORS_SCHEMA, ViewChild } from '@angular/core';
import { CustomerService } from '../services/customer.service';
import { CountryList } from '../model/CountryList.model';
import { TegelModule } from '@scania/tegel-angular-17';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { OpeningHoursComponent } from '../common/opening-hours/opening-hours.component';
import { FaxPhoneComponent } from '../common/fax-phone/fax-phone.component';
import { ExportService } from '../services/export.service';
import { UtilsService } from '../utils/utils.service';

@Component({
  selector: 'app-customer-workshops',
  standalone: true,
  imports: [
    TegelModule,
    TitleCasePipe,
    OpeningHoursComponent,
    FaxPhoneComponent,
    CommonModule,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './customer-workshops.component.html',
  styleUrl: './customer-workshops.component.css',
})
export class CustomerWorkshopsComponent {
  constructor(
    private customerService: CustomerService,
    private exportService: ExportService,
    private utilService: UtilsService
  ) {}

  countryLists: CountryList[] = [];
  CustomerWorkshopArr: any[] = [];
  serviceList: any[] = [];
  isDataLoaded: boolean = false;
  enableExport = false;
  selectedValues: any = [];
  customerWorkshop: any;

  @ViewChild('customerWorkshopRef', { static: true })
  customerWorkshopRef!: HTMLTdsDropdownElement;

  ngOnInit() {
    this.customerService
      .getCustomerWorkshopCountries()
      .subscribe((res: any) => {
        console.log(res);

        if (res && res?.CustomerWorkshopCountries) {
          this.countryLists = res.CustomerWorkshopCountries;
          this.countryLists.sort((a, b) =>
            a.CountryName.localeCompare(b.CountryName)
          );
        } else {
          this.countryLists = [];
        }
      });
  }

  changeCountry(columnName: string, country: string) {
    this.customerService
      .getcustomerWorkshopDetailsByCountry(country)
      .subscribe((res: any) => {
        try {
          if (res && Object.keys(res).length > 0) {
            this.CustomerWorkshopArr = res.CustomerWorkshopService;
            this.enableExport = true;
            this.isDataLoaded = true;
            this.customerWorkshop = res.CustomerWorkshopService[0];
            setTimeout(() => {
              this.customerWorkshopRef?.setValue(
                this.customerWorkshop?.ScaniaId
              );
            }, 200);

            this.selectedValues[columnName] = country;
          } else {
            this.isDataLoaded = false;
            this.enableExport = false;
            console.error('No Data Available for the selected Country..');
          }
        } catch (error) {
          console.error('No Data for selected Country:', error);
        }
      });
  }

  async export(type: any) {
    //let type = event?.detail?.value;
    let id: any = await this.exportService.fetchCateegoryDataByName(
      'Customer Workshop Services'
    )?.Id;
    this.createExportFilterQuery();
    const request = {
      type: type,
      categoryid: id,
      filterData: JSON.stringify(this.createExportFilterQuery()),
    };
    this.exportService.exportData(request).subscribe((res: any) => {
      if (res != undefined) {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        let a = document.createElement('a');
        if (type === 'excel') {
          a.download =
            'sisexport-CustomerWorkshopServices-' +
            this.getDateFormat() +
            '.xlsx';
        } else {
          a.download =
            'sisexport-CustomerWorkshopServices-' +
            this.getDateFormat() +
            '.' +
            type;
        }
        a.href = url;
        a.click();
        //this.selectedValues = [];
      }
    });
  }

  async exportfortest(event: any) {
    let type = event?.detail?.value;
    let id: any = await this.exportService.fetchCateegoryDataByName(
      'Customer Workshop Services'
    )?.Id;
    this.createExportFilterQuery();
    const request = {
      type: type,
      categoryid: id,
      filterData: JSON.stringify(this.createExportFilterQuery()),
    };
    this.exportService.exportData(request).subscribe((res: any) => {});
  }

  showDetails(scaniaId: string) {
    let data = this.CustomerWorkshopArr.find(
      (item: any) => item.ScaniaId === scaniaId
    );
    if (data) {
      this.customerWorkshop = data;
    }
    if (window.innerWidth > 992)
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  isSelected(data: any) {
    return this.customerWorkshop?.ScaniaId === data?.ScaniaId;
  }

  getDateFormat() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}${month}${day}`;
    return formattedDate;
  }
  createExportFilterQuery() {
    let reqarray = [];
    if (this.selectedValues) {
      for (let key in this.selectedValues) {
        let obj = { field: '', op: 'bw', data: '' };
        obj.field = key;
        obj.data = this.selectedValues[key];
        reqarray.push(obj);
      }
    }
    return reqarray;
  }

  getUrl(url: string) {
    return this.utilService.getUrl(url);
  }
}
export interface DropdownEvent {
  name: string;
  value: string;
}
