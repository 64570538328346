<tds-header>
  <tds-header-hamburger
    id="demo-hamburger"
    aria-label="Open application drawer"
    aria-haspopup="true"
    aria-expanded="false"
    (click)="toggleMobileSideMenu()"
  ></tds-header-hamburger>

  <tds-header-title style="display: none"
    >{{ title | uppercase }}
  </tds-header-title>
  <!-- <tds-header-dropdown
    slot="end"
    no-dropdown-icon
    class="user-dropdown"
    style="
      border-left: 1px solid var(--tds-header--basic-element-border);
      
    "
  >
    <div slot="icon" [hidden]="!authService.isAuthenticated">
      <img
        src="https://www.svgrepo.com/show/384676/account-avatar-profile-user-6.svg"
        alt="User menu."
        style="
          position: relative;
          margin-left: -20px;
          left: 10px;
          width: 34px;
          height: 34px;
          border-radius: 50%;
          transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
        "
      />
    </div>
  </tds-header-dropdown>
  <tds-header-item
    slot="end"
    style="
      border-right: 1px solid var(--tds-header--basic-element-border);
      border-left: 1px solid var(--tds-header--basic-element-border);
      display: block !important;
    "
    class="logout"
  >
    <div *ngIf="authService.isAuthenticated">
      <button class="logoutBtn"><i></i></button>
    </div>
  </tds-header-item>

  <tds-header-brand-symbol
    slot="end"
    class="brand"
    style="display: block !important"
  >
    <a
      href=""
      aria-label="Scania - red gryphon on blue shield"
      class="bg-white"
    ></a>
  </tds-header-brand-symbol> -->

  @for (item of menuItems; track $index) {
  <tds-header-item [selected]="item.isActive" class="item">
    <button
      class="text-wrap border-r-0 border-b-0"
      style="border-bottom: none !important"
      [style]="
        item.isActive
          ? 'border-top: 4px solid rgb(43, 112, 211) !important;'
          : ''
      "
      (click)="navigetByUrl(item)"
    >
      <span class="m-5">{{ item.title }}</span>
    </button>
  </tds-header-item>

  }
</tds-header>

@if(mobileNavOpen){
<div class="side-menu-and-main">
  <tds-side-menu
    aria-label="Side menu"
    persistent
    open="{{ mobileNavOpen }}"
    [collapsed]="collapsed"
  >
    <tds-side-menu-collapse-button>Collapse</tds-side-menu-collapse-button>
    <tds-side-menu-overlay
      (click)="toggleMobileSideMenu()"
      slot="overlay"
    ></tds-side-menu-overlay>

    <tds-side-menu-close-button
      slot="close-button"
      (click)="toggleMobileSideMenu()"
    ></tds-side-menu-close-button>

    @for (item of menuItems; track $index) {
    <tds-side-menu-item [selected]="item.isActive">
      <a (click)="navigetByUrl(item)">
        <!-- <tds-icon name="info" size="24"></tds-icon> -->
        <!-- <fa-icon [icon]="item.icon"></fa-icon> -->

        <!-- <i class="fa fa-list"></i> -->
        {{ item.title }}
      </a>
    </tds-side-menu-item>
    }

    <tds-side-menu-item slot="end">
      <div *ngIf="authService.isAuthenticated">
        <tds-side-menu-dropdown>
          @if(userUrl){ } @else {
          <!-- <tds-side-menu-user
            slot="label"
            heading="{{
              authService.identityClaims?.given_name +
                '  ' +
                authService.identityClaims?.family_name
            }}"
            img-src=""
            img-alt="GC"
          >
          </tds-side-menu-user> -->

          <tds-side-menu-item slot="label">
            <span style="position: relative; top: -2px">
              {{ authService.identityClaims?.given_name }}
              {{ authService.identityClaims?.family_name }}</span
            >
          </tds-side-menu-item>
          }

          <tds-side-menu-dropdown-list>
            <tds-side-menu-dropdown-list-item>
              <a routerLink="logout">
                <!-- <fa-icon [icon]="faPowerOff" style="width: 15px"></fa-icon> -->
                <div
                  class="tds-u-pl1"
                  (click)="logout()"
                  style="padding-left: 5px"
                >
                  Logout
                </div>
                <!--  <tds-button 
                  id="my-tooltip-button"
                  type="button"
                  style="padding-left: 18px;"
                  variant="primary"
                  size="xs"
                  mode-variant="primary"
                  text="{{ 'Logout' }}"
                  (click)="logout()"
                >
                </tds-button> -->
              </a>
            </tds-side-menu-dropdown-list-item>
          </tds-side-menu-dropdown-list>
        </tds-side-menu-dropdown>
      </div>
    </tds-side-menu-item>
  </tds-side-menu>
</div>
}
