import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { TegelModule } from '@scania/tegel-angular-17';
import { SubjectService } from './services/subject.service';
import { filter, map, mergeMap } from 'rxjs';
import { ExportService } from './services/export.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
  hideHeaderFooter: boolean = false;
  hideHeader: boolean = false;
  title = 'SISInternal';
  loading: boolean = false;
  HideBacktoTopBtn: boolean = true;

  constructor(
    public subjectService: SubjectService,
    public exportService: ExportService,
    public router: Router,
    private route: ActivatedRoute
  ) {
    this.getRouteData();
    // this.exportService.getCategories().subscribe((data: any) => {
    //   this.exportService.setCategoryData(data);
    // });
  }

  ngOnInit(): void {
    this.subjectService.tokenReceivedSubject.subscribe(
      (tokenLoaded: boolean) => {
        if (tokenLoaded && !this.exportService.fetchCategoryData().length) {
          this.exportService.getCategories().subscribe((data: any) => {
            this.exportService.setCategoryData(data);
          });
        }
      }
    );
  }

  private getRouteData() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.rootRoute(this.route)),
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
        mergeMap((route: ActivatedRoute) => route.data)
      )
      .subscribe((event: { [name: string]: any }) => {
        this.hideHeaderFooter = event['hideHeaderFooter'];
        this.hideHeader = event['hideHeader'];
      });
  }
  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  scrollFunction() {
    if (window.scrollY > 100) {
      this.HideBacktoTopBtn = false;
    } else {
      this.HideBacktoTopBtn = true;
    }
  }

  scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
